<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand mb-0 h1" href="#">BIOL/BIOC 2200</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="/lab1">Experiment 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab2" routerLinkActive="active">Experiment 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab3" routerLinkActive="active">Experiment 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab4" routerLinkActive="active">Experiment 4 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab5" routerLinkActive="active">Experiment 5 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab6" routerLinkActive="active">Experiment 6 </a>
      </li>
    </ul>
  </div>
</nav>

<div class="cContainer">

  <div class="cExercise1">

    Exercise 1
    <p class="font-italic" style="color:blue; font-size: smaller">
        Remember that new compound you discovered? After characterizing its buffering
        capability last week, you now decide to see if this compound obeys the Beer-Lambert Law of
        Absorbance (Abs=Ε c l). This Law states that the amount of light absorbed by a solution is
        directly proportional to the concentration of absorbing molecules in solution, and to the
        distance the light travels through the solution.
    </p>

    <h2 >Instructions:</h2>
    <p style="font-size: small">
        Before you can test the Beer-Lambert Law, you must determine the wavelength 
        at which the compound will absorb the maximum amount of light. 
        Use a spectrophotometer in wavescan mode (set the mode below) to measure the absorbance spectrum of the compound:
    </p>
    <button class="btn btn-primary btn-sm cWavescan" (click)="actionWavescan()"[disabled]="disableMode">Wavescan</button>
    &nbsp;
    <button class="btn btn-primary btn-sm cWavelength" (click)="actionWavelength()"[disabled]="disableMode">Wavelength</button><br>

    <ol style="padding-left: 0"><br>
      <li>
        Use a glass pipette to fill a spectrophotometric cuvette with blank reference solution (contains distilled water, 
        but no test compound)
      </li><br>
      <li>
          Place the blank cuvette in the spectrophotometer and press the ‘0A/100%T’ button to zero/blank the spec
      </li><br>
      <li>
          Use a glass pipette to fill a cuvette with the test compound dissolved in distilled water
      </li><br>
      <li>
          Place the cuvette in the spectrophotometer and press the read button to take a ‘Wavescan’ reading
      </li>
    </ol>
  </div>

  <div id="result-text"><strong>Question: </strong>What is the λmax for the novel compound? Record this wavelength</div>

  <div class="cExercise2">

    Exercise 2<br>
    <h2 >Instructions:</h2>
    <p>
        Now you can test the law of absorbance. Using the
        spectrophotometer in single wavelength (λ) mode (set the mode below), and a
        constant pathlength of 1.0 cm, measure the absorbance at
        varying concentrations of your novel compound to see
        how Abs and Ε change:
    </p>

    <ol style="padding-left: 0">
      <button class="btn btn-primary btn-sm cWavescan" (click)="actionWavescan()"[disabled]="disableMode">Wavescan</button>
      &nbsp;
      <button class="btn btn-primary btn-sm cWavelength" (click)="actionWavelength()"[disabled]="disableMode">Wavelength</button><br><br>
      <li>Enter the maximum wavelength (λmax) determined in Exercise 1</li>
      Wavelength: <input class="cWavelengthVal" type="number" required (change)="checkWavelengthVal($event)"><br><br>

      <li>Select a set of four concentrations from the dropdown menu and press the <b>Submit</b> button)</li>
      <form #form="ngForm" (ngSubmit)="onSubmit(form, 0)">
        <label for="concentrations">Select concentrations set:</label>
        &nbsp;
        <select name="concentrations" id="concentrations">
          <option selected="selected" class="placeholder">Concentration</option>
          <option value="concSet3">5.0, 10.0, 50.0, 100.0</option>
          <option value="concSet4">20.0, 40.0, 60.0, 80.0</option>
        </select>
        &nbsp;
        <input type="submit" value="Submit" class="btn btn-primary btn-sm" [disabled]="submitButtonBool">
      </form>
      <br><li>The normal procedure would be to transfer each solution of a different concentration 
          into seperate cuvettes, and then measure the absorbance of each sample one at a time
            with the spectrophotometer. However to save time, you can skip to the results 
            of your experiment. Press the <b>See Results</b> button</li>
      <button class="btn btn-primary btn-sm" (click)="showResultsEx2()"[disabled]="resultsBool">See Results</button>
    </ol>

    <div id="conctable-one"></div>
    <div id="conctable-two"></div>
    <div id="conctable-three"></div>
    <div id="conctable-four"></div>

    <div id="conc-title">
      Table 1. Absorbance at 348 nm in a 1 cm cuvette, of varying concentrations
      of novel compound in distilled water to determine extinction coefficient.														
    </div>
    <br>
    <div id="ex2-result-text">
      Record your answers to the follow-up questions about Absorbance vs concentration:
      <br><br>
      <ul>
        <li>When the concentration was diluted in half, how did the absorbance value change?</li>
        <li>When the concentration was diluted by 4-times, how did the absorbance value change?</li>
        <li>Use the Beer-Lambert equation to calculate the extinction coefficient at each concentration, then record your answers.</li>
        <li>Did the value of E change when the concentration changed, or did it remain constant?</li>
      </ul>
      <br><br>
      <button class="btn btn-primary btn-sm" (click)="restartEx()" [disabled]="repeatBool">Select different set of concentrations</button>
      &nbsp;
      <button class="btn btn-primary btn-sm"(click)="nextExercise()">Proceed to Exercise 3</button>
    </div>
  </div>

  <div class="cExercise3">

    Exercise 3<br>
    <h2 >Instructions:</h2>
    <p>
      Using the spectrophotometer in single wavelength (λ) mode (set the mode below), 
      measure the absorbance of 50 µM novel compound at varying
      pathlengths to see how Abs and Ε change:
    </p>

    <ol style="padding-left: 0">
      <button class="btn btn-primary btn-sm cWavescan" (click)="actionWavescan()"[disabled]="disableMode">Wavescan</button>
      &nbsp;
      <button class="btn btn-primary btn-sm cWavelength" (click)="actionWavelength()"[disabled]="disableMode">Wavelength</button><br><br>
      <li>Set the wavelength on the spectrophotometer to the λmax determined in Exercise 1</li>
      Wavelength: <input class="cWavelengthVal" type="number" required (change)="checkWavelengthVal($event)"><br><br>
      <li>Select a set of three cuvette sizes and press the <b>Submit</b> button</li>
      <form #form="ngForm" (ngSubmit)="onSubmit(form, 1)">
        <label for="sizes">Select pathlength set:</label>
        &nbsp;
        <select name="sizes" id="sizes">
          <option selected="selected" class="placeholder">Pathlength</option>
          <option value="cuvSet1">0.5, 1.0, 2.0 cm</option>
          <option value="cuvSet3">1.0, 2.0, 2.5 cm</option>
        </select>
        &nbsp;
        <input type="submit" value="Submit" class="btn btn-primary btn-sm" [disabled]="submitButtonBool">
      </form>
      <br>
      <li>
        The normal procedure would be to transfer each solution of a different concentration 
        into seperate cuvettes, and then measure the absorbance of each sample one at a time
        with the spectrophotometer. However to save time, you can skip to the results 
        of your experiment. Press the ‘See Results’ button
      </li>
      <button class="btn btn-primary btn-sm" (click)="showResultsEx3()"[disabled]="resultsBool">See Results</button>
    </ol>

    <div id="cuvtable-one"></div>
    <div id="cuvtable-two"></div>
    <div id="cuvtable-three"></div>

    <div id="cuv-title">Table 2. Absorbance at 348 nm of 50 µM novel compound in distilled water at varying pathlength to determine extinction coefficient.”</div>
    <br>
    <div id="ex3-result-text">
      Record your answers to the follow-up questions about Absorbance vs pathlength:
      <br><br>
      <ul>
        <li>When the pathlength was doubled, how did the absorbance value change?</li>
        <li>Use the Beer-Lambert equation to calculate the extinction coefficient at each pathlength, then record your answers.</li>
        <li>Did the value of E change when the pathlength changed, or did it remain constant?</li>
      </ul>
      <button class="btn btn-primary btn-sm" (click)="restartEx()" [disabled]="repeatBool">Select different set of pathlengths</button>
      &nbsp;
      <button class="btn btn-primary btn-sm" (click)="showExploration()" >Show 'Exploration'</button>
    </div>

    <div id="exploration-text">
      <h1>Exploration:</h1>
      <p class="font-italic">
        Does this compound obey the Beer-Lambert Law? In other words, was the change in
        absorbance directly proportional to changes in the concentration and pathlength?
      </p>
    </div>
  </div>

  <div class="cLabels" *ngIf="!disableMode && nCurrentExercise === 1">
    <div class="cMachineLabel">Spectrophotometer </div>
    <div class="cCuvetteLabel">Cuvette</div>
    <div class="cTestLabel">Solution</div>
    <div class="cPipetteLabel">Pipette</div>
  </div>

  <div id="iMachine_1" class="cMachine"></div>
  <div id="iMachine_2" class="cMachine">
    <div id="push-btn" (click)="onZeroClicked()"></div>
    <div id="read-btn" (click)="onReadClicked()"></div>
    <div id="meter">0.0</div>
  </div>

  <div id="cuvette-hidden"><span class="CuvetteFilled"></span></div>

  <div id="iPipette_blue" class="cPipette" cdkDrag (cdkDragStarted)="dragStarted($event)"
    (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode">
    <span class="cPipetteFilled"></span>
  </div>


  <div class="cBeaker" id="iBeaker">
    <span class="cBeakerFilled"></span>
  </div>
  <div id="iCuvette" cdkDrag cdkDragBoundary=".cContainer" (cdkDragStarted)="dragStarted($event)"
    (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="cuvetteFilledBool">
    <span class="cCuvetteFilled"></span>
  </div>

  <div id="graph-one">
    <button class="btn btn-primary btn-sm" (click)="nextExercise()">Move to exercise 2</button>
  </div>

  <div class="alert alert-info alert-dismissible fade show" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <strong>Experiment II: Principles of Spectrophotometry</strong>
  </div>

</div>
