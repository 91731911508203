import { Component, OnInit } from '@angular/core';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ms-lab5',
  templateUrl: './lab5.component.html',
  styleUrls: ['./lab5.component.scss']
})
export class Lab5Component {

  cuvetteOnePlaced = false;
  cuvettesPlaced = false;

  speedOneBool = true;
  speedTwoBool = true;
  speedThreeBool = true;
  speedFourBool = true;
  startBool = true;

  spinOneComplete = false;

  chosenSpeed = 1;

  constructor() { }

  // Action on drag started
  dragStarted(event: CdkDragStart) {
    const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
}

// Action after drag ended.
dragEnded(event: CdkDragEnd) {
    const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    const strItemId: string = dragItem.id;
    console.log(strItemId);
    this.updateOnItemDrag(strItemId);
}

updateOnItemDrag(strItemId: string) {
    if (strItemId === 'cuvette-1' || strItemId === 'cuvette-2') {
        this.updateCuvette();
    }
    if (strItemId === 'cone-placeholder') {
        this.updateCone();
    }
}

  // document.getElementById('machine-open').classList.add('fadeOut');
  // document.getElementById('machine-closed').classList.add('fadeIn');

updateCuvette() {
  (document.getElementById('tubes-label') as HTMLElement).style.display = 'none';
  const cuvetteOne = (document.getElementById('cuvette-1') as HTMLElement);
  const cuvetteTwo = (document.getElementById('cuvette-2') as HTMLElement);
  const hiddenOne = (document.getElementById('cuvette-hidden-1') as HTMLElement);
  const hiddenTwo = (document.getElementById('cuvette-hidden-2') as HTMLElement);
  const cuvetteOneRect = document.getElementById('cuvette-1').getBoundingClientRect();
  const cuvetteTwoRect = document.getElementById('cuvette-2').getBoundingClientRect();
  const hiddenOneRect = document.getElementById('cuvette-hidden-1').getBoundingClientRect()
  const hiddenTwoRect = document.getElementById('cuvette-hidden-2').getBoundingClientRect()

  if (Math.abs(cuvetteOneRect.left - hiddenOneRect.left) < 30 && Math.abs(cuvetteOneRect.top - hiddenOneRect.top) < 30) {
    (document.getElementById('rotor-label') as HTMLElement).style.display = 'none';
    cuvetteOne.style.display = 'none';
    hiddenOne.style.opacity = '1';
    this.cuvetteOnePlaced = true;
    alert('Centrifuge tubes must always be counter-balanced with another tube or else damage or injury can occur!');
  }
  if (Math.abs(cuvetteTwoRect.left - hiddenTwoRect.left) < 30 && Math.abs(cuvetteTwoRect.top - hiddenTwoRect.top) < 30) {
    cuvetteTwo.style.display = 'none';
    hiddenTwo.style.opacity = '1';
    hiddenOne.style.display = 'none';
    hiddenTwo.style.display = 'none';
    document.getElementById('cone').style.display = 'none';
    document.getElementById('cone-placeholder').classList.add('fadeIn');
    this.cuvettesPlaced = true;
  }
}

enableSpeedButtons(val: any) {
  this.speedOneBool = false;
  this.speedTwoBool = false;
  this.speedThreeBool = false;
  this.speedFourBool = false;
  if (val === 1) {
    this.speedOneBool = true;
  }
  this.startBool = true;
}
disableSpeedButtons() {
  this.speedOneBool = true;
  this.speedTwoBool = true;
  this.speedThreeBool = true;
  this.speedFourBool = true;
}

updateCone() {
  const cone = (document.getElementById('cone-placeholder') as HTMLElement);
  const coneHidden = (document.getElementById('cone-hidden') as HTMLElement);
  const coneRect = document.getElementById('cone-placeholder').getBoundingClientRect();
  const hiddenRect = document.getElementById('cone-hidden').getBoundingClientRect();
  if (Math.abs(coneRect.left - hiddenRect.left) < 30 && Math.abs(coneRect.top - hiddenRect.top) < 30) {
    (document.getElementById('centrifuge-label') as HTMLElement).style.display = 'none';
    cone.style.display = 'none';
    coneHidden.style.display = 'none';
    (document.getElementById('machine-open') as HTMLElement).style.opacity = '0';
    (document.getElementById('machine-closed') as HTMLElement).style.opacity = '1';
    this.enableSpeedButtons(0);
  }
}

collectOnClick() {
  (document.getElementById('collect-button') as HTMLElement).style.display = 'none';
  const text = (document.getElementById('result-text') as HTMLElement);
  text.textContent = 'You have collected the supernatant from the first spin, and now it will be subjected to a second spin to further localize the protein. Select a speed for the second next spin.'
  text.style.color = 'black';
  this.enableSpeedButtons(1);
  this.speedOneBool = true;
  this.spinOneComplete = true;

}

spinOnClick(val: any) {
  this.chosenSpeed = val;
  this.startBool = false;
  this.disableSpeedButtons();
  switch (val) {
    case 1: {
      this.speedOneBool = false;
      break;
    }
    case 2: {
      this.speedTwoBool = false;
      break;
    }
    case 3: {
      this.speedThreeBool = false;
      break;
    }
    case 4: {
      this.speedFourBool = false;
      break;
    }

  }
}

startOnClick() {
  const cuvette_pellet = (document.getElementById('cuvette-result-pellet') as HTMLElement);
  const cuvette_supernatant = (document.getElementById('cuvette-result-supernatant') as HTMLElement)
  const text = (document.getElementById('result-text') as HTMLElement);
  cuvette_pellet.style.display = 'block';
  cuvette_supernatant.style.display = 'none';
  text.style.display = 'block';
  if (!this.spinOneComplete) {
    switch (this.chosenSpeed) {
      case 1: {
        this.chosenSpeed = 1;
        text.textContent = 'Great! You now know the protein is not found in the Nuclear fraction (cell wall or nucleus). Collect the supernatant and proceed to a second spin.';
        text.style.color = 'green';
        cuvette_supernatant.style.display = 'block';
        cuvette_pellet.style.display = 'none';
        (document.getElementById('collect-button') as HTMLElement).style.display = 'block';
        this.startBool = true;
        break;
      }
      case 2: {
        this.chosenSpeed = 2;
        text.textContent = 'Uh-oh! The protein is in either the Nuclear fraction or Mitochondrial fraction, and needs to be further localized. Select a lower speed.';
        text.style.color = 'red';
        this.enableSpeedButtons(0);
        break;
      }
      case 3: {
        this.chosenSpeed = 3;
        text.textContent = 'Uh-oh! The protein is in either the Nuclear fraction, Mitochondrial fraction or Microsomal Fraction, and needs to be further localized. Select a lower speed.';
        text.style.color = 'red';
        this.enableSpeedButtons(0);
        break;
      }
      case 4: {
        this.chosenSpeed = 4;
        text.textContent = 'Oh no! The protein can’t be localized because all of the cell fractions are in the pellet. Select a lower speed.';
        text.style.color = 'red';
        this.enableSpeedButtons(0);
        break;
      }
    }
  }
  else {
    switch (this.chosenSpeed) {
      case 2: {
        this.chosenSpeed = 2;
        text.textContent = 'Great! The protein is found in the Mitochondrial fraction (mitochondria, chloroplasts, lysosomes, peroxisomes).';
        text.style.color = 'green';
        break;
      }
      case 3: {
        this.chosenSpeed = 3;
        text.textContent = 'Uh-oh! The protein is in either the Mitochondrial fraction or Microsomal Fraction and needs to be further localized. Select a lower speed';
        text.style.color = 'red';
        this.enableSpeedButtons(1);
        break;
      }
      case 4: {
        this.chosenSpeed = 4;
        text.textContent = 'Oh no! The protein can’t be localized because all three cell fractions are in the pellet. Select a lower speed.';
        text.style.color = 'red';
        this.enableSpeedButtons(1);
        break;
      }
    }
  }
}



}

