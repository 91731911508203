<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand mb-0 h1" href="#">BIOL/BIOC 2200</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab1" routerLinkActive="active">Experiment 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab2" routerLinkActive="active">Experiment 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab3" routerLinkActive="active">Experiment 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab4" routerLinkActive="active">Experiment 4 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab5" routerLinkActive="active">Experiment 5 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab6" routerLinkActive="active">Experiment 6 </a>
      </li>
    </ul>
  </div>
</nav>

<div class="container">
  <div class="base"></div>

  <div id="graph-one"></div>
  <div id="graph-two"></div>
  <div id="graph-three"></div>

  <div id="beaker-hidden"><span class="beaker-filled"></span></div>
  <div id="burette-hidden"></div>
  <div id="burette-filled"></div>

  <div id="biuret-label">Buret</div>
  <div id="beaker-label">Beaker</div>
  <div id="stirplate-label">Stir plate</div>
  <div id="stopcock-label">Stopcock</div>

  <div id="exploration-text">
    <h1>Exploration:</h1>
    It’s a buffer! Record your answers to the follow up questions:
    <br><br>
    <ul>
      <li>What is the pKa of this weak acid? (Hint: use the interpolation line to read the pH)</li>
      <li>What volume of 0.2 M NaOH was required to reach the pKa for this concentration of weak acid?</li>
      <li>What volume of 0.2 M NaOH was required to reach the end point for this concentration of weak acid?</li>
    </ul>
    Repeat the titrations until you have tested all three concentrations of weak acid.
    <br><br>
    <!--
      <a href="https://hellomusa.github.io/biochem_lab_simulator/lab1">	
     -->
      <a href="https://biolabsim.carleton.ca/lab1">
      <button class="btn btn-primary btn-sm"> Click to restart and choose a different concentration</button>
    </a>
  </div>

  <div class="text">

    <p class="font-italic" style="color:blue; font-size: small">
      You discover a new compound and you decide to test if it is a buffer that resists changes in pH.
      In other words, is it a weak acid capable of partially dissociating to form a conjugate base and
      a proton?
    </p>

    <h2>Instructions:</h2>

    <ol style="padding-left: 0">
    <li>
      <u>Drag and drop</u> the beaker onto the stir plate and the buret onto the retort stand
    </li>
    <br>
    <li>
      <u>Click</u> the <b>Fill Buret</b> button to fill the buret with 0.2 M NaOH (strong base)
    </li>
    <button class="btn btn-primary btn-sm" [disabled]="buretteButtonBool" (click)="onBuretteBtnClicked()" >Fill Buret</button>
    <br><br>
    <li>
      <u>Click</u> one of the concentrations below to fill the beaker with a solution of your compound in water
    </li>
    <button class="btn btn-primary btn-sm" [disabled]="conc1ButtonBool" (click)="onConcentrationBtnClicked(0.05)">0.05 M</button>
    &nbsp;
    <button class="btn btn-primary btn-sm" [disabled]="conc2ButtonBool" (click)="onConcentrationBtnClicked(0.1)"> 0.1 M</button>
    &nbsp;
    <button class="btn btn-primary btn-sm" [disabled]="conc3ButtonBool" (click)="onConcentrationBtnClicked(0.2)">0.2 M</button>
    <br><br>
    <li>
      <u>Click</u> the stopcock on the buret to open it and <u>keep clicking</u> the button below to drop NaOH into the beaker in 1 mL increments until you see a graph of the results
    </li>
    <button class="btn btn-primary btn-sm" [disabled]="dropButtonBool" (click)="onDropBtnClicked()">Drop 1 mL of NaOH (Total: {{counter}} mL)</button>
    </ol>
  </div>
  
  <div id="beaker" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"></div>
  <div id="burette" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"></div>
  
  <div id="water-drop"></div>
  <div class="magnet"></div>
  
  <div id="push-btn" (click)="onStopcockClicked()"></div>

  <div class="alert alert-info alert-dismissible fade show" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <strong>Experiment I: Buffers and the Effect of pH on Amino Acids</strong>
  </div>

</div>
