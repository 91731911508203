import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ms-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  showDesc1: boolean = false;
  showDesc2: boolean = false;
  showDesc3: boolean = false;
  showDesc4: boolean = false;
  showDesc5: boolean = false;
  showDesc6: boolean = false;
}