<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand mb-0 h1" href="#">BIOL/BIOC 2200</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="#">Experiment 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab2" routerLinkActive="active">Experiment 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab3" routerLinkActive="active">Experiment 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab4" routerLinkActive="active">Experiment 4 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab5" routerLinkActive="active">Experiment 5 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab6" routerLinkActive="active">Experiment 6 </a>
      </li>
    </ul>
  </div>
</nav>

<div class="cContainer">
    
    <div class="alert alert-info alert-dismissible fade show" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            <strong>EXPERIMENT VI: Protein Fractionation by Gel Filtration</strong>
    </div>

    <div id="label1">Column</div>
    <div id="label2">Retort stand</div>
    
    <div id="graph1"></div>
    <div id="graph2"></div>
    <div id="graph3"></div>
    
    <div id="instructions">
        <p class="font-italic" style="color:blue; font-size: small">
            To further characterize your new enzyme, you purify it (to remove crude impurities) using gel permeation filtration.
            This separates components of a mixture by size as they filter through a column packed with gel resin.
            To your crude enzyme sample, you add two size marker compounds (large and small) to help you determine
            when to start and stop collecting the fractions, since the crude sample is colourless. The large marker is
            Dextran Blue (blue) and the small marker is Vitamin B12 (pink). Your purified enzyme will emerge from the
            column between these two markers.
        </p>
        <h2 >Instructions:</h2>
        <ol style="padding-left: 0">
            <li>Clamp the packed column onto the retort stand (click and drag the column to the stand)</li>
            <br><li>Load the sample mixture (Click the top of the column)</li>
            <br><li>Click on the stopcock to open it and start the flow. Continue clicking the stopcock to collect 1-mL fractions of eluent (flow-through) into separate cuvettes (5 drops per cuvette).</li>
        </ol>
    </div>
    <div id="results">
        <h2 >Results:</h2>
        <p class="font-italic" style="font-size: small">
            One can easily see the cuvettes that contain the large size marker (Dextran Bue) and small size marker
            (Vitamin B12), but the purified enzyme is colourless. Analyse the collected fractions (cuvettes)
            by taking absorbance readings of the large and small markers, and perform an enzyme assay to detect 
            the purified enzyme. The data is used to create an elution profile that shows in which cuvette the
            purified enzyme eluted from the column relative to the size marker compounds.
        </p>
        <h3 >Procedure:</h3>
        <ol style="padding-left: 0">
            <li>Measure the absorbance of each fraction at 620 nm, the wavelength at which Dextran Blue (large marker) absorbs light</li>
            <button class="btn btn-primary btn-sm" [disabled]="g1" (click)="graph1()">620nm</button><br>
            <br><li>Measure the absorbance of each fraction at 540 nm, the wavelength at which Vitamin B12 (small marker) absorbs light</li>
            <button class="btn btn-primary btn-sm" [disabled]="g2" (click)="graph2()">540nm</button><br>
            <br><li>Perform an enzyme assay by mixing a small volume of each fraction with the enzyme’s substrate (recall the novel compound you discovered) and measure the presence of product at 405 nm</li>
            <button class="btn btn-primary btn-sm" [disabled]="g3" (click)="graph3()">405nm</button><br>
        </ol>   
    </div>

    <div id="questions">
        Record your answers to the follow-up questions below:
        <ul>
          <li>Which fraction contained the majority of the purified Dextran Blue? Vitamin B12? Enzyme?</li>
          <li>Which compound eluted from the column 1st? 2nd? Last?</li>
          <li>In which order of size did the compounds elute from the column: largest to smallest, or smallest to largest?</li>
        </ul>

    </div>
    <div id="iPipette_1" class="cPipette" cdkDrag cdkDragBoundary=".cContainer" (cdkDragStarted)="dragStarted($event)"
        (cdkDragEnded)="dragEnded($event)">
        <span class="cPipetteFilled"></span>
    </div>
    <div id="iStand" class="cStand"></div>
    <div id="iStand_Pipette" class="cStand">
        <div id="iAddBtn" (click)="onAddClicked()"></div>
    </div>
    <div id="iStand_Pipette_Filled" class="cStand cStand_Pipette_Filled1">
        <div class="cPinkChemical"></div>
        <div class="cBlueChemical"></div>
        <div id="iOpenBtn" (click)="onOpenClicked()"></div>
    </div>
    <div id="iWaterDrop"></div>
    <div id="iCuvetteHolder" class="cCuvetteHolder">
        <div class="cCuvette">
            <div class="cLightBlueChemical1"></div>
        </div>
        <div class="cCuvette">
            <div class="cBlueChemical"></div>
        </div>
        <div class="cCuvette">
            <div class="cLightBlueChemical2"></div>
        </div>
        <div class="cCuvette">
            <div class="cYellowChemical"></div>
        </div>
        <div class="cCuvette">
            <div class="cYellowChemical"></div>
        </div>
        <div class="cCuvette">
            <div class="cYellowChemical"></div>
        </div>
        <div class="cCuvette">
            <div class="cYellowChemical"></div>
        </div>
        <div class="cCuvette">
            <div class="cLightPinkChemical1"></div>
        </div>
        <div class="cCuvette">
            <div class="cPinkChemical"></div>
        </div>
        <div class="cCuvette">
            <div class="cLightPinkChemical2"></div>
        </div>
        
    </div>

</div>