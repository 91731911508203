/* tslint:disable:max-line-length */
import { Component, OnDestroy } from '@angular/core';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';
import { FormBuilder, NgForm } from '@angular/forms';

@Component({
    selector: 'ms-lab3',
    templateUrl: './lab3.component.html',
    styleUrls: ['./lab3.component.scss']
})
export class Lab3Component implements OnDestroy {
    nTolerance = 20;
    isTrayFilled1 = false;
    isTrayFilled2 = false;
    intervalId1: any;
    intervalId2: any;
    hasTakenChemical = false;
    hasTakenChemical2 = false;
    trayNumber = 1;
    ex2Data: string[] = [];

    chosenConcs: string[] = [];

    table = 'table1';

    c1 = '5';
    c2 = '10';
    pair = this.c1 + '' + this.c2;
    buffer = 0;

    constructor() {
    }

    ngOnDestroy() {
        clearInterval(this.intervalId1);
        clearInterval(this.intervalId2);
    }

    // Action on drag started
    dragStarted(event: CdkDragStart) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    }

    // Action after drag ended.
    dragEnded(event: CdkDragEnd) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
        const strItemId: string = dragItem.id;
        this.updateOnItemDrag(strItemId);
    }

    updateOnItemDrag(strItemId: string) {
        switch (strItemId) {
            case 'iPipette_1':
            case 'iPipette_2':
                this.updateTrayOnPipetteMove(strItemId);
                break;
            case 'iTray':
                this.updateMachineOnTrayMove(strItemId);
                break;
            case 'iPipette_multichannel':
                this.updateTrayOnMultiChlPipetteMove(strItemId);
                break;
            case 'iCuvette':
                this.updateCuvette();
                break;
            // case 'iBlackCone':
            //     this.updateBlackCone();
            //     break;

        }
    }

    updateTrayOnPipetteMove(strItemId: string) {
        const dragItemRect = (document.getElementById(strItemId) as HTMLElement).getBoundingClientRect();
        const trayRect = (document.getElementById('iTray') as HTMLElement).getBoundingClientRect();
        const beakerRect = (document.getElementById('iBeakerHolder') as HTMLElement).getBoundingClientRect();

        (document.getElementById('pipette-label') as HTMLElement).style.display = 'none';


        if (this.hasTakenChemical) {
            if (trayRect.left < dragItemRect.left && (dragItemRect.left + dragItemRect.width) < (trayRect.left + trayRect.width) &&
                (trayRect.top + 10) < (dragItemRect.top + dragItemRect.height) && (dragItemRect.top + dragItemRect.height) < (trayRect.top + trayRect.height - 20)) {
                    if (this.trayNumber < 7) {
                        this.updateTray();
                        (document.getElementById('tray-label') as HTMLElement).style.display = 'none';
                    }
                    (document.querySelector('#iPipette_1 .cPipetteFilled') as HTMLElement).style.opacity = '0';
                    this.hasTakenChemical = false;

            }
        } else {
            if (Math.abs(beakerRect.left - dragItemRect.left) < 150 && (beakerRect.top - dragItemRect.top) < 200 && (beakerRect.top - dragItemRect.top) > 130) {
                (document.querySelector('#iPipette_1 .cPipetteFilled') as HTMLElement).style.opacity = '1';
                this.hasTakenChemical = true;
            } else if (trayRect.left < dragItemRect.left && (dragItemRect.left + dragItemRect.width) < (trayRect.left + trayRect.width) &&
                (trayRect.top + 10) < (dragItemRect.top + dragItemRect.height) && (dragItemRect.top + dragItemRect.height) < (trayRect.top + trayRect.height - 20)) {
                alert('Pick chemical first.');
            }
        }
    }

    updateTray() {
        this.trayNumber++;
        if (this.trayNumber === 7) {
            this.isTrayFilled1 = true;
            (document.getElementById('iPipette_1') as HTMLElement).style.display = 'none';
        } else if (this.trayNumber == 10) {
            (document.getElementById('iPipette_multichannel') as HTMLElement).style.display = 'none';
            this.startTimer();
        }
        // Check if on dev or prod
        if (window.location.href.includes("localhost")) {
          document.getElementById('iTray').style.backgroundImage = `url(../../assets/images/lab3/tray_${this.trayNumber}.png)`;
        } else {
          document.getElementById('iTray').style.backgroundImage = `url(../../biochem_lab_simulator/assets/images/lab3/tray_${this.trayNumber}.png)`;
        }
    }

    updateMachineOnTrayMove(strItemId: string) {
        const dragItemRect = (document.getElementById(strItemId) as HTMLElement).getBoundingClientRect();
        const machineRect = (document.getElementById('iMachine_1') as HTMLElement).getBoundingClientRect();

        (document.getElementById('tray-label') as HTMLElement).style.display = 'none';

        if (Math.abs(dragItemRect.left - (machineRect.left + 82)) < 50 && Math.abs(dragItemRect.top - (machineRect.top + 88)) < 50) {
            if (this.isTrayFilled2) {
                (document.getElementById('iMachine_1') as HTMLElement).style.display = 'none';
                (document.getElementById('iMachine_1_close') as HTMLElement).style.display = 'block';
                (document.getElementById('iTray') as HTMLElement).style.display = 'none';
                (document.getElementById('iBeakerHolder') as HTMLElement).style.display = 'none';
                (document.getElementById('iTrough') as HTMLElement).style.display = 'none';
                (document.getElementById('text2') as HTMLElement).style.display = 'block';
                (document.getElementById('graph-1') as HTMLElement).style.display = 'block';
                (document.getElementById('trough-label') as HTMLElement).style.display = 'none';
                (document.getElementById('holder-label') as HTMLElement).style.display = 'none';
                (document.getElementById('tray-label') as HTMLElement).style.display = 'none';
                (document.getElementById('pipette-label') as HTMLElement).style.display = 'none';
                (document.getElementById('machine-label') as HTMLElement).style.display = 'none';
            }
        }
    }

    onEx2Click(){
        (document.getElementById('instructions1') as HTMLElement).style.display = 'none';
        (document.getElementById('instructions2') as HTMLElement).style.display = 'block';
        (document.getElementById('graph') as HTMLElement).style.display = 'none';
        (document.getElementById('iMachine_1_close') as HTMLElement).style.display = 'none';
        (document.getElementById('text2') as HTMLElement).style.display = 'none';
        (document.getElementById('graph-1') as HTMLElement).style.display = 'none';
    }

    updateTimer(strItemId: string) {
        document.getElementById(strItemId).classList.add('fadeOut');
        document.getElementById('iMachine_1').classList.add('fadeOut');
        document.getElementById('iMachine_1_close').classList.add('fadeIn');
        let nTimer = 10;
        this.intervalId1 = setInterval(() => {
            nTimer--;
            const timer = (document.querySelector('#iMachine_1_close .cTimer') as HTMLElement);
            timer.innerText = String(nTimer);

            if (nTimer === 0) {
            clearInterval(this.intervalId1);
            timer.style.display = 'none';
            (document.querySelector('#iMachine_1 span') as HTMLElement).innerHTML = '8';
            }
        }, 1000);
    }

    updateTrayOnMultiChlPipetteMove(strItemId: string) {
        const dragItemRect = (document.getElementById(strItemId) as HTMLElement).getBoundingClientRect();
        const trayRect = (document.getElementById('iTray') as HTMLElement).getBoundingClientRect();
        const troughRect = (document.getElementById('iTrough') as HTMLElement).getBoundingClientRect();

        (document.getElementById('multipipette-label') as HTMLElement).style.display = 'none';

        if (this.hasTakenChemical2) {
            if (trayRect.left < dragItemRect.left && (dragItemRect.left + dragItemRect.width) < (trayRect.left + trayRect.width) &&
                (trayRect.top) < (dragItemRect.top + dragItemRect.height) && (dragItemRect.top + dragItemRect.height) < (trayRect.top + trayRect.height - 20)) {
                if (this.isTrayFilled1) {
                    if (this.trayNumber < 10) {
                        this.updateTray();
                    }
                    this.hasTakenChemical2 = false;
                    document.querySelectorAll('#iPipette_multichannel .cPipetteFilled').forEach(element => {
                        (element as HTMLElement).style.opacity = '0';
                    });
                }
            }
        } else {
            if (troughRect.left < (dragItemRect.left + dragItemRect.width) && dragItemRect.left < (troughRect.left + troughRect.width) &&
                troughRect.top < (dragItemRect.top + dragItemRect.height + 42) && (dragItemRect.top + dragItemRect.height) < (troughRect.top + troughRect.height - 20)) {
                document.querySelectorAll('#iPipette_multichannel .cPipetteFilled').forEach(element => {
                    (element as HTMLElement).style.opacity = '1';
                });
                this.hasTakenChemical2 = true;
            } else if (trayRect.left < dragItemRect.left && (dragItemRect.left + dragItemRect.width) < (trayRect.left + trayRect.width) &&
                (trayRect.top) < (dragItemRect.top + dragItemRect.height) && (dragItemRect.top + dragItemRect.height) < (trayRect.top + trayRect.height - 20)) {
                alert('Pick chemical first.');
            }
        }
    }

    getKeyByValue(object: Object, value: any) {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const element = object[key];
                //console.log(element.name, element.value)
                if (element.value === value) {
                    return element.name;
                }
            }
        }
    }

    updateCuvette() {
        const cuvetteRect = (document.getElementById('iCuvette') as HTMLElement).getBoundingClientRect();
        const blackConeRect = (document.getElementById('iBlackCone') as HTMLElement).getBoundingClientRect();

        if (Math.abs(cuvetteRect.left - (blackConeRect.left + blackConeRect.width / 2)) < this.nTolerance &&
            Math.abs((cuvetteRect.top + cuvetteRect.height) - blackConeRect.top) < this.nTolerance) {
            const cuvette = (document.getElementById('iCuvette') as HTMLElement);
            const blackCone = (document.getElementById('iBlackCone') as HTMLElement);
            blackCone.appendChild(cuvette);
            cuvette.style.transform = 'none';
            cuvette.style.left = '36px';
            cuvette.style.top = '-15px';
            cuvette.removeAttribute('cdkDrag');
        }
    }

    public concValue: string[];
    public concentrations: { [key: string]: Object; }[] = [
        { name: '2', value: 'Protein volume (mL): 2.5, dH2O volume (mL): 2.5, Expected Concentration: 500 μg/mL' },
        { name: '5', value: 'Protein volume (mL): 1, dH2O volume (mL): 4, Expected Concentration: 200 μg/mL' },
        { name: '10', value: 'Protein volume (mL): 0.5, dH2O volume (ml): 4.5, Expected Concentration: 100 μg/ml' },
        { name: '20', value: 'Protein volume (mL): 0.25, dH2O volume (ml): 4.75, Expected Concentration: 50 μg/ml'},
        { name: '50', value: 'Protein volume (mL): 0.1, dH2O volume (mL): 4.9, Expected Concentration: 20 μg/ml' },
        { name: '100', value: 'Protein volume (mL): 0.05, dH2O volume (mL): 4.95, Expected Concentration: 10 μg/mL' }   
        ];
    public concFields: Object = { text: 'name', value: 'value' };
    public concWaterMark: string = 'Select dilution factors';
    onSubmit(form: NgForm): void {
        (document.getElementById('exercise3') as HTMLElement).style.display = 'block';
        (document.getElementById('ex3text1') as HTMLElement).style.display = 'block';
        this.ex2Data = [];
        this.chosenConcs = [];
        const defaultValConc: any[] = ["Protein volume (mL): 1, dH2O volume ml: 4, Expected Concentration: 200(μg/ml)"];
        const selectedConcentrations = form.value || defaultValConc;
        form.resetForm();
        const concValues = Object.keys(selectedConcentrations).map(key => selectedConcentrations[key]);
        let concKey;
        let concabs;
        concValues.forEach(output => {
            output.forEach(element => {
                concKey = this.getKeyByValue(this.concentrations, element);
                concabs = element;
                this.ex2Data.push(`Dilution factor: ${concKey}, ${concabs}`);
                this.chosenConcs.push(concKey);
            });
            // {{result.concentration}}µM Absorbtion = {{result.absorbtion}}µM^-1cm^-1
        });
        console.log(this.ex2Data);
        console.log(this.chosenConcs);

    }

    resetFormData() {
        this.ex2Data = [];
    }

    onResultsClick() {
        this.generateTable(0);
        (document.getElementById('ex3text1') as HTMLElement).style.display = 'none';
        (document.getElementById('ex3text2') as HTMLElement).style.display = 'block';
        (document.getElementById('iMachine_1_close') as HTMLElement).style.display = 'none';
    }

    onAnalyzeClick() {
        (document.getElementById('graph') as HTMLElement).style.display = 'block';
        (document.getElementById('exploration') as HTMLElement).style.display = 'block';
        (document.getElementById('instructions2') as HTMLElement).style.display = 'none';
        (document.getElementById(this.table) as HTMLElement).style.display = 'none';
        this.generateTable(1);
        
    }

    showTable(val: any) {
        this.table = 'table' + String(val+this.buffer);
        (document.getElementById(this.table) as HTMLElement).style.display = 'block';
    }

    generateTable(val: any) {
        this.c1 = this.chosenConcs[0];
        this.c2 = this.chosenConcs[1];
        let big;
        let small;
        if (Number(this.c1) > Number(this.c2)) {
            big = this.c1;
            small = this.c2;
        } else {
            big = this.c2;
            small = this.c1;
        }
        this.pair = small + ' ' + big;
        console.log(this.pair);

        if (val === 1) {
            this.buffer = 15;
        }

        if (this.pair === '2 5') {
            this.showTable(1);
        } else if (this.pair === '2 10') {
            this.showTable(2);
        } else if (this.pair === '2 20') {
            this.showTable(3);
        } else if (this.pair === '2 50') {
            this.showTable(4);
        } else if (this.pair === '2 100') {
            this.showTable(5);
        } else if (this.pair === '5 10') {
            this.showTable(6);
        } else if (this.pair === '5 20') {
            this.showTable(7);
        } else if (this.pair === '5 50') {
            this.showTable(8);
        } else if (this.pair === '5 100') {
            this.showTable(9);
        } else if (this.pair === '10 20') {
            this.showTable(10)
        } else if (this.pair === '10 50') {
            this.showTable(11);
        } else if (this.pair === '10 100') {
            this.showTable(12);
        } else if (this.pair === '20 50') {
            this.showTable(13);
        } else if (this.pair === '20 100') {
            this.showTable(14);
        } else if (this.pair === '50 100') {
            this.showTable(15);
        }
    }

    onRepeatClick() {
        (document.getElementById('instructions2') as HTMLElement).style.display = 'block';
        (document.getElementById('graph') as HTMLElement).style.display = 'none';
        (document.getElementById('exploration') as HTMLElement).style.display = 'none';
        (document.getElementById('exercise3') as HTMLElement).style.display = 'none';
        (document.getElementById('ex3text1') as HTMLElement).style.display = 'none';
        (document.getElementById('ex3text2') as HTMLElement).style.display = 'none';
        (document.getElementById(this.table) as HTMLElement).style.display = 'none';
        this.buffer = 0;

    }

    counter: { min: number, sec: number }

    startTimer() {
      this.counter = { min: 4, sec: 59 } // choose whatever you want
      const intervalId = setInterval(() => {
        if (this.counter.sec - 1 == -1) {
          this.counter.min -= 1;
          this.counter.sec = 59
        } 
        else this.counter.sec -= 1
        if (this.counter.min === 0 && this.counter.sec == 0) {
            clearInterval(intervalId);
            this.isTrayFilled2 = true;
            (document.querySelector('.cTimer') as HTMLElement).style.opacity = '0';
        }
      }, 50)
    }
}
