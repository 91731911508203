/* tslint:disable:max-line-length */
import { Component } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { CdkDragStart, CdkDragEnd, DragRef, CdkDrag } from '@angular/cdk/drag-drop';

@Component({
    selector: 'ms-lab2',
    templateUrl: './lab2.component.html',
    styleUrls: ['./lab2.component.scss']
})
export class Lab2Component {

    constructor(public fb: FormBuilder) {
    }
    concentration: string[] = ['1.0', '5.0', '10.0', '20.0', '40.0', '60.0', '80', '100'];
    cuvette: string[] = ['0.5', '1.0', '2.0', '2.5'];
    strPipetteColor = '';
    nSelConcentration = 1;
    nSelCuvette = 0.5;
    nTolerance = 30;
    strSelMachine = '';
    hasTakenChemical = false;
    nCurrentExercise = 1;
    isExercise1Part1 = true;
    cuvetteFilledBool = true;
    disableMode = false;
    exercise1Val: number[] = [348];
    exercise2Val: number[] = [348];
    exercise3Val: number[] = [348];
    valCounter: number = 0;
    arrDragRef: DragRef<CdkDrag<any>>[] = [];
    wavelengthValue: number = 0;
    wavelengthCorrectVal: number = 348;
    extCoef: number = 0.016;
    ex2Data: string[] = [];
    ex3Data: string[] = [];

    submitButtonBool = true;
    resultsBool = true;
    repeatBool = false;

    concentrationSet = 'concSet1';
    cuvetteSet = 'cuvSet1';

    actionWavescan() {
        if (this.nCurrentExercise != 1)
            alert("Incorrect mode selected");
        else {
            this.disableMode = true;
        }
    }

    actionWavelength() {
        if (this.nCurrentExercise == 1)
            alert('Incorrect mode selected');
        else {
            this.disableMode = true;
        }
    }

    // Action on drag started
    dragStarted(event: CdkDragStart) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    }

    // Action after drag ended.
    dragEnded(event: CdkDragEnd) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
        const strItemId: string = dragItem.id;
        this.updateOnItemDrag(strItemId);
        this.storeUniqueDragItem(event.source._dragRef);
    }

    storeUniqueDragItem(_drag: DragRef<CdkDrag<any>>) {
        if (this.arrDragRef.indexOf(_drag) === -1)
            this.arrDragRef.push(_drag);
    }

    updateOnItemDrag(strItemId: string) {
        if (strItemId === 'iPipette_blue' || strItemId === 'iPipette_green') {
            this.updatePipette(strItemId);
        } else if (strItemId === 'iCuvette') {
            this.updateCuvette();
        }
    }

    updatePipette(strItemId: string) {
        const dragItemRect = (document.getElementById(strItemId) as HTMLElement).getBoundingClientRect();
        const cuvetteRect = (document.getElementById('iCuvette') as HTMLElement).getBoundingClientRect();
        const beakerRect = (document.getElementById('iBeaker') as HTMLElement).getBoundingClientRect();

        if (this.hasTakenChemical) {
            if (Math.abs(dragItemRect.left - cuvetteRect.left) < this.nTolerance &&
                Math.abs((dragItemRect.top + dragItemRect.height) - (cuvetteRect.top + cuvetteRect.height / 2)) < this.nTolerance) {
                (document.querySelector('.cCuvetteFilled') as HTMLElement).style.display = 'block';
                this.strPipetteColor = strItemId.split('_')[1];
                this.cuvetteFilledBool = false;
            }
        } else {
            if (beakerRect.left < (dragItemRect.left + dragItemRect.width / 2) && dragItemRect.left < (beakerRect.left + 25) &&
                beakerRect.top < (dragItemRect.top + dragItemRect.height) && (dragItemRect.top + dragItemRect.height) < (beakerRect.top + beakerRect.height)) {
                (document.querySelector('#iPipette_blue .cPipetteFilled') as HTMLElement).style.opacity = '1';
                this.hasTakenChemical = true;
            } else if (Math.abs(dragItemRect.left - cuvetteRect.left) < this.nTolerance &&
                Math.abs((dragItemRect.top + dragItemRect.height) - (cuvetteRect.top + cuvetteRect.height / 2)) < this.nTolerance) {
                alert('Pick chemical first.');
            }
        }
    }

    updateCuvette() {
        const cuvetteRect = (document.getElementById('iCuvette') as HTMLElement).getBoundingClientRect();
        const hiddenCuvetteRect = (document.getElementById('cuvette-hidden') as HTMLElement).getBoundingClientRect();

        if (Math.abs(cuvetteRect.left - hiddenCuvetteRect.left) < this.nTolerance && Math.abs(cuvetteRect.top - hiddenCuvetteRect.top) < this.nTolerance) {
            (document.getElementById('iCuvette') as HTMLElement).style.display = 'none';
            (document.getElementById('cuvette-hidden') as HTMLElement).style.opacity = '1';
            (document.querySelector('.CuvetteFilled') as HTMLElement).style.display = 'block';

            if (this.isExercise1Part1) {
                this.isExercise1Part1 = false;
                (document.getElementById('push-btn') as HTMLElement).style.display = 'block';
            }
            else
                (document.getElementById('read-btn') as HTMLElement).style.display = 'block';
        }
    }

    onZeroClicked() {
        (document.getElementById('meter') as HTMLElement).style.display = 'block';
        (document.getElementById('push-btn') as HTMLElement).style.display = 'none';

        setTimeout(() => {
            this.resetToSolution();
        }, 1000);
    }

    onReadClicked() {
        (document.getElementById('meter') as HTMLElement).style.display = 'block';
        let exerciseVal: number[];

        if (this.nCurrentExercise === 1)
            exerciseVal = this.exercise1Val;
        else if (this.nCurrentExercise === 2)
            exerciseVal = this.exercise2Val;
        else
            exerciseVal = this.exercise3Val;
        
        (document.getElementById('meter') as HTMLElement).innerHTML = String(exerciseVal[this.valCounter++]);
        if (this.valCounter >= exerciseVal.length) {
            console.log('Made it!');
            this.showResults();

        }
    }

    showMachine1Reading() {
        if (this.strPipetteColor === '') {
            alert('Select pipette first.');
        } else {
            const cuvette = (document.getElementById('iCuvette') as HTMLElement);
            cuvette.style.transform = 'none';
            cuvette.style.left = '456px';
            cuvette.style.top = '311px';
        }
    }

    resetToSolution() {
        let objFreeDragPosition: { x: number, y: number } = { x: 0, y: 0 };
        this.arrDragRef.forEach(element => {
            element.setFreeDragPosition(objFreeDragPosition);
        });

        (document.querySelector('#iPipette_blue') as HTMLElement).style.transform = 'none';
        (document.querySelector('#iPipette_blue .cPipetteFilled') as HTMLElement).style.opacity = '0';
        (document.querySelector('#iPipette_blue .cPipetteFilled') as HTMLElement).style.backgroundColor = '#636F57';

        (document.querySelector('#iCuvette') as HTMLElement).style.display = 'block';
        (document.querySelector('#cuvette-hidden') as HTMLElement).style.opacity = '0.4';
        (document.querySelector('.CuvetteFilled') as HTMLElement).style.display = 'none';
        (document.querySelector('.CuvetteFilled') as HTMLElement).style.backgroundColor = '#636F57';

        (document.querySelector('#iCuvette') as HTMLElement).style.transform = 'none';
        (document.querySelector('.cBeakerFilled') as HTMLElement).style.backgroundColor = '#636F57';
        (document.querySelector('.cCuvetteFilled') as HTMLElement).style.backgroundColor = '#636F57';
        (document.querySelector('.cCuvetteFilled') as HTMLElement).style.display = 'none';

        this.cuvetteFilledBool = true;
        this.hasTakenChemical = false;
    }

    checkWavelengthVal(event: Event) {
        this.wavelengthValue = (event.currentTarget as any).value;
        if(this.wavelengthValue != this.wavelengthCorrectVal) {
            alert('Incorrect wavelength!');
        }
        else {
            this.submitButtonBool = false;
        }
    }

    // // Hide instructions and display graph, exploration questions
    showResults() {
        (document.querySelector('.cExercise' + this.nCurrentExercise) as HTMLElement).style.display = 'none';
        (document.querySelector('#read-btn') as HTMLElement).style.display = 'none';
        if (this.nCurrentExercise === 1) {
            (document.getElementById('graph-one') as HTMLElement).style.display = 'block';
            (document.getElementById('result-text') as HTMLElement).style.display = 'block';
        }
        else if (this.nCurrentExercise === 2)
            (document.getElementById('graph-two') as HTMLElement).style.display = 'block';
        else {
            (document.getElementById('graph-three') as HTMLElement).style.display = 'block';
            (document.getElementById('exploration-text') as HTMLElement).style.display = 'block';
        }

    }

    hideAllGraph() {
        (document.getElementById('graph-one') as HTMLElement).style.display = 'none';
    }

    hideEx1Items() {
        (document.getElementById('iMachine_2') as HTMLElement).style.display = 'none';
        (document.getElementById('iPipette_blue') as HTMLElement).style.display = 'none';
        (document.getElementById('cuvette-hidden') as HTMLElement).style.display = 'none';
        (document.querySelector('.cBeaker') as HTMLElement).style.display = 'none';
        (document.getElementById('result-text') as HTMLElement).style.display = 'none';
    }

    nextExercise() {
        this.resetFormData();
        if (this.nCurrentExercise === 1) {
            this.hideEx1Items();
        }
        this.hideAllGraph();
        this.nCurrentExercise++;
        if (this.nCurrentExercise === 3) {
            (document.querySelector('.cExercise2') as HTMLElement).style.display = 'none';
        }
        //this.resetToSolution();
        this.valCounter = 0;
        this.disableMode = false;
        (document.getElementById('meter') as HTMLElement).style.display = 'none';
        (document.querySelector('.cExercise' + this.nCurrentExercise) as HTMLElement).style.display = 'block';
    }

    refresh(): void {
        window.location.reload();
    }

    getKeyByValue(object: Object, value: any) {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const element = object[key];
                //console.log(element.name, element.value)
                if (element.value === value) {
                    return element.name;
                }
            }
        }
    }

    onSubmit(form: NgForm, id: number) {
        if (id === 0) {
            this.concentrationSet = (document.getElementById('concentrations') as HTMLSelectElement).value;
            if (this.concentrationSet !== 'Concentration') {
                this.submitButtonBool = true;
                this.resultsBool = false;
            }
        } else if (id === 1) {
            this.cuvetteSet = (document.getElementById('sizes') as HTMLSelectElement).value;
            if (this.cuvetteSet !== 'Pathlength') {
                this.submitButtonBool = true;
                this.resultsBool = false;
            }
        }
    }

    showTable(id: number) {
        if (id === 0) {
            switch(this.concentrationSet) {
                case 'concSet1':
                    (document.getElementById('conctable-one') as HTMLElement).style.display = 'block';
                    (document.getElementById('conctable-two') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-three') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-four') as HTMLElement).style.display = 'none';
                    break;
                case 'concSet2':
                    (document.getElementById('conctable-two') as HTMLElement).style.display = 'block';
                    (document.getElementById('conctable-one') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-three') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-four') as HTMLElement).style.display = 'none';
                    break;
                case 'concSet3':
                    (document.getElementById('conctable-three') as HTMLElement).style.display = 'block';
                    (document.getElementById('conctable-two') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-one') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-four') as HTMLElement).style.display = 'none';
                    break;
                case 'concSet4':
                    (document.getElementById('conctable-four') as HTMLElement).style.display = 'block';
                    (document.getElementById('conctable-two') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-three') as HTMLElement).style.display = 'none';
                    (document.getElementById('conctable-one') as HTMLElement).style.display = 'none';
                    break;
            }
        }
        else {
            switch(this.cuvetteSet) {
                case 'cuvSet1':
                    (document.getElementById('cuvtable-one') as HTMLElement).style.display = 'block';
                    (document.getElementById('cuvtable-two') as HTMLElement).style.display = 'none';
                    (document.getElementById('cuvtable-three') as HTMLElement).style.display = 'none';
                    break;
                case 'cuvSet2':
                    (document.getElementById('cuvtable-two') as HTMLElement).style.display = 'block';
                    (document.getElementById('cuvtable-one') as HTMLElement).style.display = 'none';
                    (document.getElementById('cuvtable-three') as HTMLElement).style.display = 'none';
                    break;
                case 'cuvSet3':
                    (document.getElementById('cuvtable-three') as HTMLElement).style.display = 'block';
                    (document.getElementById('cuvtable-two') as HTMLElement).style.display = 'none';
                    (document.getElementById('cuvtable-one') as HTMLElement).style.display = 'none';
                    break;
            }
        }
    }

    showResultsEx2() {
        this.showTable(0);
        this.resultsBool = true;
        this.repeatBool = false;
        (document.getElementById('conc-title') as HTMLElement).style.display = 'block';
        (document.getElementById('ex2-result-text') as HTMLElement).style.display = 'block';
    }

    showResultsEx3() {
        this.showTable(1);
        this.resultsBool = true;
        this.repeatBool = false;
        (document.getElementById('cuv-title') as HTMLElement).style.display = 'block';
        (document.getElementById('ex3-result-text') as HTMLElement).style.display = 'block';
    }

    restartEx() {
        this.repeatBool = true;
        this.submitButtonBool = false;
    }

    showExploration() {
        (document.getElementById('exploration-text') as HTMLElement).style.display = 'block';
    }

    resetFormData() {
        this.ex2Data = [];
        this.ex3Data = [];
    }
}
