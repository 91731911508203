<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand mb-0 h1" href="#">BIOL/BIOC 2200</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="#">Experiment 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab2" routerLinkActive="active">Experiment 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab3" routerLinkActive="active">Experiment 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab4" routerLinkActive="active">Experiment 4 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab5" routerLinkActive="active">Experiment 5 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab6" routerLinkActive="active">Experiment 6 </a>
      </li>
    </ul>
  </div>
</nav>

<div class="cContainer">

    <div id="ph5"></div>
    <div id="ph6"></div>
    <div id="ph7"></div>
    <div id="ph56"></div>
    <div id="ph57"></div>
    <div id="ph67"></div>
    <div id="ph567"></div>

    <div id="repeat">
        Repeat the experiment until all three pH levels have been tested.
        <button class="btn btn-primary btn-sm" [disabled]="repeatBool" (click)="actionRepeat()">Repeat</button>
    </div>

    <div id="finish-btn"><br><button class="btn btn-primary btn-sm" (click)="actionExplore()">Show follow-up questions</button></div>

    <div id="explore-text">
        <p>
            For how long did the enzyme’s reaction rate (umol/min) remain constant (i.e. linear) at each substrate pH?
            <br><br>At which substrate pH was there the highest and lowest enzyme activity at 20 min of reaction time?
            <br><br>For each substrate pH, how long would you need to run the reaction to form 1 µmol of product?
        </p>
    </div>
    
    <div id="instructions">
        <p class="font-italic" style="color:blue; font-size: smaller;">
          That new binding protein found in bean sprouts is an enzyme – exciting!
          To determine its optimum pH, carry out enzyme reactions at various pH and observe the highest activity.
          The substrate for this enzyme is the novel compound you discovered; it is converted to a product by the enzyme and the reaction is stopped by increasing the overall pH in the reaction.
          This changes the product’s colour from clear to yellow at alkaline pH, hence the amount of product made over time can be measured through absorbance.
        </p>
        <h2 >Instructions:</h2>
        <ol style="padding-left: 0">
        <li>Select the desired susbstrate pH</li>
        <button class="btn btn-primary btn-sm" [disabled]="ph5ButtonBool" (click)="actionPh(5)">pH 5</button>
        &nbsp;
        <button class="btn btn-primary btn-sm" [disabled]="ph6ButtonBool" (click)="actionPh(6)">pH 6</button>
        &nbsp;
        <button class="btn btn-primary btn-sm" [disabled]="ph7ButtonBool" (click)="actionPh(7)">pH 7</button><br>
        <br><li>Place the cuvette of substrate and the test tube of enzyme in the 37oC water bath to equilibrate (Drag and drop items to green placeholders)</li>
        <br><li>To begin the reaction, using the micropipettor with a tip on the end of it draw the enzyme from the test tube
            and add it to the substrate
        </li>
        <br><li>To stop the reaction, using a glass pipette draw solution from the NaOH bottle and dispense 0.1 NaOH in the cuvette </li>

    </ol>
    </div>

    <div id="iMachine_open" class="cMachine"></div>

    <div id="cuvette-hidden"><span class="cuvetteFilled"></span></div>
    <div id="beaker-hidden"><span class="beakerFilled"></span></div>

    <div id="cuvette-3" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode"><span class="cuvetteFilled"></span></div>
    <div id="cuvette-2" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode"><span class="cuvetteFilled"></span></div>
    <div id="cuvette" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode"><span class="cuvetteFilled"></span></div>
   
    <div id="beaker-3" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode"><span class="beakerFilled"></span></div>
    <div id="beaker-2" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode"><span class="beakerFilled"></span></div>
    <div id="beaker" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode"><span class="beakerFilled"></span></div>



    <div id="bottle" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!disableMode"></div>

    <div id="micropipettor-3" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"><span class="micropipettorFilled"></span></div>
    <div id="micropipettor-2" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"><span class="micropipettorFilled"></span></div>
    <div id="micropipettor" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"><span class="micropipettorFilled"></span></div>

    <div id="pipette-3" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"><span class="pipetteFilled"></span></div>
    <div id="pipette-2" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"><span class="pipetteFilled"></span></div>
    <div id="pipette" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"><span class="pipetteFilled"></span></div>

    <div id="cuvette-label">2 mM substrate</div>
    <div id="beaker-label">20 µg/mL enzyme</div>
    <div id="bottle-label">0.1 M NaOH</div>
    <div id="waterbath-label">Waterbath</div>
    <div id="micropipettor-label">Micropipettor</div>
    <div id="pipette-label">Pipette</div>


    <div id="iMachine_close" class="cMachine"></div>

    <div class="alert alert-info alert-dismissible fade show" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            <strong>Experiment IV: Enzymology   A Study of Wheat Germ Acid Phosphatase</strong>
    </div>

</div>