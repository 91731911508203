<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand mb-0 h1" href="#">BIOL/BIOC 2200</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="#">Experiment 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab2" routerLinkActive="active">Experiment 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab3" routerLinkActive="active">Experiment 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab4" routerLinkActive="active">Experiment 4 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab5" routerLinkActive="active">Experiment 5 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab6" routerLinkActive="active">Experiment 6 </a>
      </li>
    </ul>
  </div>
</nav>

<div class="cContainer">
    <div id="instructions">
        <p class="font-italic" style="color:blue; font-size: small">
            Remember the protein from before, which can bind to your novel compound? 
            While you know it can be isolated from bean sprouts, you do not know the
            organelle or location within the cell where the protein is found. So,
            you decide to use differential centrifugation in order to determine
            in which fraction of the cell the protein is present (subcellular 
            localization). The protein can be detected by using the enzyme 
            assay you used before.
        </p>
        <h2 >Instructions:</h2>
        <ol style="padding-left: 0">
        <li>After collecting bean sprout stem tissue, homogenizing it in protective media with a blender, and filtering through cheesecloth, you poured the crude homogenate into two centrifuge tubes. Place the equally balanced centrifuge tubes in the rotor.
        </li>
        <br><li>Refer to Table 1 of the Introduction to Experiment 5 in the lab manual (also shown on the right), 
            which outlines the centrifuge speeds typically used to fractionate biological cells. Your choice of 
            speed will determine whether the protein will be found in the pellet (sediment) or remain in solution (the supernatant).
            Drag the rotor to the centrifuge and select a spin speed.
        </li>
        <div id="spin-table">
            Table 5.1.  Composition of cell fractions pelleted at selected RCF.
            <table>
            <tr>
              <th>Fraction Name</th>
              <th>Celluar Components</th>
              <th>RCF (x g)</th>
              <th>Time</th>
            </tr>
            <tr>
              <td>Nuclear</td>
              <td>nuclei, cell wall fragments, whole cells</td>
              <td>600</td>
              <td>10 min</td>
            </tr>
            <tr>
              <td>Mitochondrial</td>
              <td>mitochondria, chloroplasts, lysosomes, peroxisomes</td>
              <td>20,000</td>
              <td>10 min</td>
            </tr>
            <tr>
              <td>Microsomal</td>
              <td>endoplasmic reticulum, Golgi bodies, ribosomes</td>
              <td>50,000</td>
              <td>60 min</td>
            </tr>
            <tr>
              <td>Cytosol</td>
              <td>soluble proteins, lipids, RNA</td>
              <td>>100,000</td>
              <td>3 hr</td>
            </tr>
          </table>
        </div>
        <button class="btn btn-primary btn-sm" [disabled]="speedOneBool" (click)="spinOnClick(1)">600 x g</button>
        &nbsp;
        <button class="btn btn-primary btn-sm" [disabled]="speedTwoBool" (click)="spinOnClick(2)">20,000 x g</button>
        &nbsp;
        <button class="btn btn-primary btn-sm" [disabled]="speedThreeBool" (click)="spinOnClick(3)">50,000 x g</button>
        &nbsp;
        <button class="btn btn-primary btn-sm" [disabled]="speedFourBool" (click)="spinOnClick(4)">100,000 x g</button><br>
        <br>
        <li>
          Press 'Start' to begin centrifugation. Remember to record all of your results.
        </li>
        <button class="btn btn-success" [disabled]="startBool" (click)="startOnClick()">Start</button>
    </ol>
    </div>

    <div id="cuvette-result-pellet"></div>
    <div id="cuvette-result-supernatant"></div>

    <div id="result-text">
        Great! You now know the protein is not found in the Nuclear fraction (cell wall or nucleus). 
        Collect the supernatant and proceed to a second spin
    </div>
    <div id="collect-button"><button class="btn btn-primary btn-sm" (click)="collectOnClick()" >Collect Supernatant</button></div>


    <div id="cone"></div>
    <div id="machine-open"></div>
    <div id="machine-closed"></div>
    <div id="cone-hidden"></div>
    <div id="cuvette-hidden-1"></div>
    <div id="cuvette-hidden-2"></div>
    <div id="cone-placeholder" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!cuvettesPlaced"></div>
    <div id="cuvette-1" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)"></div>
    <div id="cuvette-2" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!cuvetteOnePlaced"></div>
    <div id="tubes-label">Centrifuge Tubes</div>
    <div id="rotor-label">Rotor</div>
    <div id="centrifuge-label">Centrifuge</div>

    <div class="alert alert-info alert-dismissible fade show" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
          <strong>EXPERIMENT V: Cellular Fractionation of Mung Bean Seedlings for Studying Citric Acid Cycle</strong>
  </div>

</div>