import { Component, OnInit } from '@angular/core';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-lab6',
  templateUrl: './lab6.component.html',
  styleUrls: ['./lab6.component.scss']
})
export class Lab6Component implements OnInit {
  nWaterDropCount = 0;
  nMaxDropInCuvette = 5;
  nCurrentcCuvette = 1;
  nTotalCuvette = 10;

  g1 = false;
  g2 = true;
  g3 = true;

  constructor() { }

  ngOnInit() {
  }

  // Action on drag started
  dragStarted(event: CdkDragStart) {
    let dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
  }

  // Action after drag ended.
  dragEnded(event: CdkDragEnd) {
    let dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    let strItemId: string = dragItem.id;
    this.updateOnItemDrag(strItemId);
  }

  updateOnItemDrag(strItemId: string) {
    if (strItemId == 'iPipette_1') {
      this.updateStandOnPipetteMove(strItemId);
    }
  }

  updateStandOnPipetteMove(strItemId: string) {
    document.getElementById('label1').style.opacity = '0';
    document.getElementById('label2').style.opacity = '0';
    let dragItemRect = (document.getElementById(strItemId) as HTMLElement).getBoundingClientRect();
    let standRect = (document.getElementById('iStand') as HTMLElement).getBoundingClientRect();

    if (standRect.left < (dragItemRect.left + dragItemRect.width / 2) && dragItemRect.left < (standRect.left + 40) &&
      (standRect.top + standRect.height / 2) < (dragItemRect.top + dragItemRect.height) &&
      (dragItemRect.top + dragItemRect.height) < (standRect.top + standRect.height / 2 + 125)) {
      document.getElementById('iPipette_1').classList.add('fadeOut');
      document.getElementById('iStand').classList.add('fadeOut');
      document.getElementById('iStand_Pipette').classList.add('fadeIn');
    }
  }

  onAddClicked() {
    document.getElementById('iAddBtn').style.display = 'none';
    document.getElementById('iStand_Pipette').classList.add('fadeOut');
    document.getElementById('iStand_Pipette_Filled').classList.add('fadeIn');
    document.getElementById('iStand_Pipette_Filled').style.pointerEvents = 'auto';
    document.getElementById('iCuvetteHolder').classList.add('fadeIn');
    document.getElementById('iCuvetteHolder').style.right = '-273px';
    document.querySelectorAll('cCuvette').forEach(element => {
      element.classList.add('fadeIn');
    });
  }

  onOpenClicked() {
    let waterDrop = document.getElementById('iWaterDrop');
    let newWaterDrop = waterDrop.cloneNode(true) as HTMLElement;
    newWaterDrop.style.display = 'block';
    waterDrop.parentNode.appendChild(newWaterDrop);
    setTimeout((newWaterDrop) => {
      newWaterDrop.style.display = 'none';
      this.nWaterDropCount++;

      if (this.nWaterDropCount > 4) {
        switch (this.nCurrentcCuvette) {
          case 1:
            //(document.querySelector('#iStand_Pipette_Filled .cBlueChemical') as HTMLElement).style.opacity = '0';
            (document.querySelector('.cCuvette .cLightBlueChemical1') as HTMLElement).style.opacity = '1';
            break;
          case 2:
            //(document.querySelector('#iStand_Pipette_Filled .cRedChemical') as HTMLElement).style.opacity = '0';
            (document.querySelector('.cCuvette .cBlueChemical') as HTMLElement).style.opacity = '1';
            break;
          case 3:
            (document.querySelector('#iStand_Pipette_Filled .cBlueChemical') as HTMLElement).style.opacity = '0';
            (document.querySelector('.cCuvette .cLightBlueChemical2') as HTMLElement).style.opacity = '1';
            break;
          // case 4:
          //   //(document.querySelector('#iStand_Pipette_Filled .cYellowChemical') as HTMLElement).style.opacity = '0';
          //   (document.querySelector('.cCuvette .cYellowChemical') as HTMLElement).style.opacity = '1';
          //   break;
          // case 5:
          //   //(document.querySelector('#iStand_Pipette_Filled .cYellowChemical') as HTMLElement).style.opacity = '0';
          //   (document.querySelector('.cCuvette .cYellowChemical') as HTMLElement).style.opacity = '1';
          //   break;
          // case 6:
          //   //(document.querySelector('#iStand_Pipette_Filled .cYellowChemical') as HTMLElement).style.opacity = '0';
          //   (document.querySelector('.cCuvette .cYellowChemical') as HTMLElement).style.opacity = '1';
          //   break;
          // case 7:
          //   //(document.querySelector('#iStand_Pipette_Filled .cYellowChemical') as HTMLElement).style.opacity = '0';
          //   (document.querySelector('.cCuvette .cYellowChemical') as HTMLElement).style.opacity = '1';
          //   break;
          case 8:
            //(document.querySelector('#iStand_Pipette_Filled .cYellowChemical') as HTMLElement).style.opacity = '0';
            (document.querySelector('.cCuvette .cLightPinkChemical1') as HTMLElement).style.opacity = '1';
            break;
          case 9:
            //(document.querySelector('#iStand_Pipette_Filled .cYellowChemical') as HTMLElement).style.opacity = '0';
            (document.querySelector('.cCuvette .cPinkChemical') as HTMLElement).style.opacity = '1';
            break;
          case 10:
            (document.querySelector('#iStand_Pipette_Filled .cPinkChemical') as HTMLElement).style.opacity = '0';
            (document.querySelector('.cCuvette .cLightPinkChemical2') as HTMLElement).style.opacity = '1';
            break;

        }
      }

      if (this.nWaterDropCount === this.nMaxDropInCuvette)
        this.moveCuvetteRight();
    }, 1500, newWaterDrop);
  }

  moveCuvetteRight() {
    if (this.nCurrentcCuvette === this.nTotalCuvette) {
      this.showResults();
    }
    if (this.nCurrentcCuvette < this.nTotalCuvette) {
      if (this.nCurrentcCuvette === 1) {
        document.querySelectorAll('#iStand_Pipette_Filled .cYellowChemical, #iStand_Pipette_Filled .cBrownChemical, #iStand_Pipette_Filled .cRedChemical').forEach(element => {
          (element as HTMLElement).style.opacity = '1';
        });
        
        (document.getElementById('iStand_Pipette_Filled') as HTMLElement).classList.add('cStand_Pipette_Filled2');
      }

      this.nWaterDropCount = 0;
      let nLeft = parseInt((document.getElementById('iCuvetteHolder').style.right).split('px')[0]);
      document.getElementById('iCuvetteHolder').style.right = nLeft + 28 + 'px';

      this.nCurrentcCuvette++;
    }
  }

  showResults() {
    document.getElementById('instructions').style.opacity = '0';
    document.getElementById('results').style.opacity = '1';
    document.getElementById('iCuvetteHolder').style.opacity = '0';
    document.getElementById('iStand_Pipette_Filled').style.opacity = '0';
  }

  graph1() {
    console.log("got here");
    this.g1 = true;
    this.g2 = false;
    document.getElementById('graph1').style.opacity = '1';
  }
  
  graph2() {
    this.g2 = true;
    this.g3 = false;
    document.getElementById('graph1').style.opacity = '0';
    document.getElementById('graph2').style.opacity = '1';
  }

  graph3() {
    this.g3 = true;
    document.getElementById('graph1').style.opacity = '0';
    document.getElementById('graph2').style.opacity = '0';
    document.getElementById('graph3').style.opacity = '1';
    document.getElementById('questions').style.opacity = '1';
  }


}
