<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<!-- Header -->
<header class="w3-container w3-blue w3-center" style="padding:50px 16px">
  <i class="material-icons" style="font-size: 100px">biotech</i>
  <h1 class="w3-margin w3-jumbo">BIOL/BIOC 2200 <strong>Lab Simulations</strong></h1>
  <br><br>
  <p class="w3-large">The following exercises are computer simulations of the experiments you will do in the lab.</p>
</header>

<!-- First Grid -->
<div class="w3-row-padding w3-padding-64 w3-container">
  <div class="w3-content">
    <div class="w3-twothird">
      <h1><strong>Jump to an experiment</strong></h1>
      <hr>
      <div class="labs">
        <div class="labs lab--1">
          <a routerLink="/lab1" routerLinkActive="active">
            <mat-card (mouseover)="showDesc1 = true" (mouseleave)="showDesc1 = false">
              <mat-card-title style="color: #2196F3">Exp. I</mat-card-title>
              <mat-card-content *ngIf = "showDesc1">Buffers and the Effect of pH on Amino Acids</mat-card-content>
            </mat-card>
          </a>
        </div>
        <div class="labs lab--2">
          <a routerLink="/lab2" routerLinkActive="active">
            <mat-card (mouseover)="showDesc2 = true" (mouseleave)="showDesc2 = false">
              <mat-card-title style="color: #2196F3">Exp. II</mat-card-title>
              <mat-card-content *ngIf = "showDesc2">Principles of Spectrophotometry</mat-card-content>
            </mat-card>
          </a>
        </div>
        <div class="labs lab--3">
          <a routerLink="/lab3" routerLinkActive="active">
            <mat-card (mouseover)="showDesc3 = true" (mouseleave)="showDesc3 = false">
              <mat-card-title style="color: #2196F3">Exp. III</mat-card-title>
              <mat-card-content *ngIf = "showDesc3">Protein Assays</mat-card-content>
            </mat-card>
          </a>
        </div>
        <div class="labs lab--4">
          <a routerLink="/lab4" routerLinkActive="active">
            <mat-card (mouseover)="showDesc4 = true" (mouseleave)="showDesc4 = false">
              <mat-card-title style="color: #2196F3">Exp. IV</mat-card-title>
              <mat-card-content *ngIf = "showDesc4">Enzymology: A Study of Wheat Germ Acid Phosphatase</mat-card-content>
            </mat-card>
          </a>
        </div>
        <div class="labs lab--5">
          <a routerLink="/lab5" routerLinkActive="active">
            <mat-card (mouseover)="showDesc5 = true" (mouseleave)="showDesc5 = false">
              <mat-card-title style="color: #2196F3">Exp. V</mat-card-title>
              <mat-card-content *ngIf = "showDesc5">Cellular Fractionation of Mung Bean Seedlings for Studying Citric Acid Cycle</mat-card-content>
            </mat-card>
          </a>
        </div>
        <div class="labs lab--6">
          <a routerLink="/lab6" routerLinkActive="active">
            <mat-card (mouseover)="showDesc6 = true" (mouseleave)="showDesc6 = false">
              <mat-card-title style="color: #2196F3">Exp. VI</mat-card-title>
              <mat-card-content *ngIf = "showDesc6">Protein Fractionation by Gel Filtration</mat-card-content>
            </mat-card>
          </a>
        </div>
      </div>
    </div>
    <h1 style="color: red"><strong>Important information</strong></h1>
    <hr>
    <ul>
      <li><h3>Click on "Back" and "Next" at the bottom to move from one experiment to the next. (Or use the navigation bar at the top left of each lab page)</h3></li>
      <li><h3>Refresh the page to re-start an experiment.</h3></li>
      <li><h3>Follow embedded instructions for carrying out simulations.</h3></li>
      <li><h3>Record your results and answers to the follow-up questions for doing the lab quiz for each experiment.</h3></li>
    </ul>
  </div>
</div>

<!-- Footer -->
<footer class="w3-container w3-center w3-opacity"> 
  <p>Made with care by
    <a href="https://hellomusa.github.io"> Musa Ali</a>,
    <a href="https://hsohail.com"> Hamza Sohail</a>, and
    <a href="https://www.github.com/shane-f03">Shane Fernandes</a>
  </p>
</footer>
