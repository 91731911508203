<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand mb-0 h1" href="#">BIOL/BIOC 2200</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab1" routerLinkActive="active">Experiment 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab2" routerLinkActive="active">Experiment 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab3" routerLinkActive="active">Experiment 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab4" routerLinkActive="active">Experiment 4 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab5" routerLinkActive="active">Experiment 5 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lab6" routerLinkActive="active">Experiment 6 </a>
      </li>
    </ul>
  </div>
</nav>

<div class="cContainer">

    <div id="trough-label">Trough</div>
    <div id="holder-label"> BSA concentration test tubes (µg/mL)</div>
    <div id="tray-label">Microwell Plate</div>
    <div id="pipette-label">Micropipettor</div>
    <div id="multipipette-label">Multichannel Micropipettor</div>
    <div id="machine-label">Microwell Plate-reader</div>

    <div id=graph></div>
    <div id=graph-1></div>
    
    <div id="exploration">
      Record your answers to the follow-up questions below:
      <ul>
        <li>What is the original protein concentration for each dilution?</li>
        <li>Why is the measured concentration not available for certain dilutions?</li>
        <li>Determine the measured concentration of a sample with an absorbance of 0.500 by interpolating from the standard curve</li>
      </ul>
      <br>
      <br>Repeat the experiment until you have tested all six of the dilutions (press the <b>Repeat</b> button below)
      <button class="btn btn-primary btn-sm" (click)="onRepeatClick()">Repeat</button>
    </div>

        <div id="instructions1">
            Exercise 1
            <p class="font-italic" style="color:blue; font-size: small">
                While characterizing your novel compound, you discovered that it is
                capable of specifically binding to a protein found in bean sprouts. 
                You decide to extract and purify the binding protein to use it in 
                various studies, but before you can use it, you must measure its
                protein concentration.
            </p>
            <h2 >Instructions:</h2>
            <ol style="padding-left: 0">
            <p style="font-size: small">
                The first step is to prepare a protein standard curve (calibration curve)
                using a standard protein prepared as a series of known protein concentrations.
                Bovine serum albumin (BSA) is frequently used as a standard protein, and a
                commonly used protein assay is the Bradford assay
            </p>
            <li>
                Using a micropipettor with a tip on the end, transfer standard BSA 
                solutions ranging from zero (blank) to 250 µg/mL, into three 
                microwells for each concentration (triplicate wells)
            </li>
            <br><li>
                Now using a multichannel micropipettor with multiple tips,
                transfer Coomassie Brilliant Blue assay reagent from the 
                reagent trough to each column of wells. Wait at least 5 min 
                (up to 60 min)
                </li>
            <br><li>Insert the microwell plate in a microwell plate-reader to measure the absorbance of the standards at 595 nm</li>
        </ol>

        </div>
        <div id="instructions2">
            Exercises 2 and 3
            <p class="font-italic" style="color:blue"> While characterizing your novel compound, you discovered that it is capable of specifically binding to a protein found in bean sprouts. You decide to extract and purify the binding protein to use it in various studies, but before you can use it, you must measure its protein concentration.
            </p>
            <h2 >Instructions:</h2>
            <ol style="padding-left: 0">
            <p>The literature suggests that the concentration of your purified protein could be up to ~1000 µg/mL, 
                but your standard curve only measures up to 250 µg/mL. You must choose dilutions that will bring 
                your protein into an appropriate range for quantification (250 µg/mL).
                Choose <b>two</b> dilution factors below
            </p>
            <div class="ex2">
                <form #form='ngForm' (ngSubmit)="onSubmit(form)">
                    <div class="form-group"> 
                        <ejs-multiselect [maximumSelectionLength]=2 id='concData' name='name'  #local='ngModel' [(ngModel)]='concValue' [dataSource]='concentrations' [fields]='concFields' [placeholder]='concWaterMark'></ejs-multiselect>
                    </div>
                    <div class = "col-sm">
                        <button class="btn btn-primary btn-sm" type="submit" ejs-button>Submit</button>
                    </div>
                </form>
            
                <hr>
                
                <div *ngIf="ex2Data">
                    <ul>
                        <li *ngFor="let result of ex2Data">
                            {{result}}
                        </li> 
                    </ul>   
                </div>
            </div>
        </ol>
        </div>

        <div id="text2">
          Note the range of absorbance readings on the y-axis that correspond to the range of concentrations on the x-axis.
          <br><br>
          Now you can test a solution of your purified binding protein and use the standard curve to estimate its concentration.
          <button class="btn btn-primary btn-sm" (click)="onEx2Click()">Proceed to Exercise 2</button>
        </div>

        <div id="exercise3">
            <div id="ex3text1">
                <p>
                    You calculated (using C1V1=C2V2) the volumes needed to make 5 mL of each diluted sample, 
                    and you have prepared the two chosen dilutions of your purified protein in distilled water. 
                    You will now repeat the Bradford assay to determine the concentrations of the diluted samples.
                    <br><br>
                    The normal procedure would be to transfer each dilution into triplicate wells of the
                    microwell plate, then add the assay reagent, wait 5-60 min, then measure the absorbance
                    of each sample  with the plate-reader. However to save time, you can skip to the
                    results of your experiment. Press the <b>See Results</b> button.
                    <button class="btn btn-primary btn-sm" (click)="onResultsClick()">See Results</button>
                </p>
            </div>
                  <table class="table" id="table1">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>500</td>
                        <td>>1.0</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                    </tr>
                  </table>
                  <table class="table" id="table2">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>500</td>
                        <td>>1.0</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>100</td>
                      <td>0.415</td>
                    </tr>
                </table>
                <table class="table" id="table3">
                  <tr>
                      <th>Dilution Factors</th>
                      <th>Expected Concentration (µg/mL)</th>
                      <th>Average Absorbance (595nm)</th>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>500</td>
                      <td>>1.0</td>
                  </tr>
                  <tr>
                      <td>20</td>
                      <td>50</td>
                      <td>0.204</td>
                  </tr>
                </table>
                <table class="table" id="table4">
                  <tr>
                      <th>Dilution Factors</th>
                      <th>Expected Concentration (µg/mL)</th>
                      <th>Average Absorbance (595nm)</th>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>500</td>
                      <td>>1.0</td>
                  </tr>
                  <tr>
                      <td>50</td>
                      <td>20</td>
                      <td>0.103</td>
                  </tr>
                </table>
                <table class="table" id="table5">
                  <tr>
                      <th>Dilution Factors</th>
                      <th>Expected Concentration (µg/mL)</th>
                      <th>Average Absorbance (595nm)</th>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>500</td>
                      <td>>1.0</td>
                  </tr>
                  <tr>
                    <td>100</td>
                    <td>10</td>
                    <td>&lt;0.1*</td>
                  </tr>
                </table>
                <table class="table" id="table6">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>100</td>
                      <td>0.415</td>
                    </tr>
                </table>
                <table class="table" id="table7">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                    </tr>
                </table>
                <table class="table" id="table8">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                    </tr>
                </table>
                <table class="table" id="table9">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                    </tr>
                </table>
                <table class="table" id="table10">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>100</td>
                        <td>0.415</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                    </tr>
                </table>
                <table class="table" id="table11">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>100</td>
                        <td>0.415</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                    </tr>
                </table>
                <table class="table" id="table12">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>100</td>
                        <td>0.415</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                    </tr>
                </table>
                <table class="table" id="table13">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                    </tr>
                </table>
                <table class="table" id="table14">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                    </tr>
                </table>
                <table class="table" id="table15">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                    </tr>
                </table>

                  <table class="table" id="table16">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured Concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>500</td>
                        <td>>1.0</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                        <td>194.7</td>
                        <td>973.5</td>
                    </tr>
                </table>
                <table class="table" id="table17">
                  <tr>
                      <th>Dilution Factors</th>
                      <th>Expected Concentration (µg/mL)</th>
                      <th>Average Absorbance (595nm)</th>
                      <th>Measured concentration (µg/mL)</th>
                      <th>Original Concentration (µg/mL)</th>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>500</td>
                      <td>>1.0</td>
                      <td>N/A</td>
                      <td>N/A</td>
                  </tr>
                  <tr>
                      <td>10</td>
                      <td>100</td>
                      <td>0.415</td>
                      <td>102.3</td>
                      <td>1023</td>
                  </tr>
                </table>
                <table class="table" id="table18">
                  <tr>
                      <th>Dilution Factors</th>
                      <th>Expected Concentration (µg/mL)</th>
                      <th>Average Absorbance (595nm)</th>
                      <th>Measured concentration (µg/mL)</th>
                      <th>Original Concentration (µg/mL)</th>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>500</td>
                      <td>>1.0</td>
                      <td>N/A</td>
                      <td>N/A</td>
                  </tr>
                  <tr>
                      <td>20</td>
                      <td>50</td>
                      <td>0.204</td>
                      <td>48.4</td>
                      <td>968</td>
                  </tr>
                </table>
                <table class="table" id="table19">
                  <tr>
                      <th>Dilution Factors</th>
                      <th>Expected Concentration (µg/mL)</th>
                      <th>Average Absorbance (595nm)</th>
                      <th>Measured concentration (µg/mL)</th>
                      <th>Original Concentration (µg/mL)</th>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>500</td>
                      <td>>1.0</td>
                      <td>N/A</td>
                      <td>N/A</td>
                  </tr>
                  <tr>
                      <td>50</td>
                      <td>20</td>
                      <td>0.103</td>
                      <td>24</td>
                      <td>1200</td>
                  </tr>
                </table>
                <table class="table" id="table20">
                  <tr>
                      <th>Dilution Factors</th>
                      <th>Expected Concentration (µg/mL)</th>
                      <th>Average Absorbance (595nm)</th>
                      <th>Measured concentration (µg/mL)</th>
                      <th>Original Concentration (µg/mL)</th>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>500</td>
                      <td>>1.0</td>
                      <td>N/A</td>
                      <td>N/A</td>
                  </tr>
                  <tr>
                      <td>100</td>
                      <td>10</td>
                      <td>&lt;0.1*</td>
                      <td>N/A</td>
                      <td>N/A</td>
                  </tr>
                </table>
                <table class="table" id="table21">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                        <td>194.7</td>
                        <td>973.5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>100</td>
                        <td>0.415</td>
                        <td>102.3</td>
                        <td>1023</td>
                    </tr>
                </table>
                <table class="table" id="table22">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                        <td>194.7</td>
                        <td>973.5</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                        <td>48.4</td>
                        <td>968</td>
                    </tr>
                </table>
                <table class="table" id="table23">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                        <td>194.7</td>
                        <td>973.5</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                        <td>24</td>
                        <td>1200</td>
                    </tr>
                </table>
                <table class="table" id="table24">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>200</td>
                        <td>0.736</td>
                        <td>194.7</td>
                        <td>973.5</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                </table>
                <table class="table" id="table25">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>100</td>
                        <td>0.415</td>
                        <td>102.3</td>
                        <td>1023</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                        <td>48.4</td>
                        <td>968</td>
                    </tr>
                </table>
                <table class="table" id="table26">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>100</td>
                        <td>0.415</td>
                        <td>102.3</td>
                        <td>1023</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                        <td>24</td>
                        <td>1200</td>
                    </tr>
                </table>
                <table class="table" id="table27">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>100</td>
                        <td>0.415</td>
                        <td>102.3</td>
                        <td>1023</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                </table>
                <table class="table" id="table28">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                        <td>48.4</td>
                        <td>968</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                        <td>24</td>
                        <td>1200</td>
                    </tr>
                </table>
                <table class="table" id="table29">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>50</td>
                        <td>0.204</td>
                        <td>48.4</td>
                        <td>968</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                </table>
                <table class="table" id="table30">
                    <tr>
                        <th>Dilution Factors</th>
                        <th>Expected Concentration (µg/mL)</th>
                        <th>Average Absorbance (595nm)</th>
                        <th>Measured concentration (µg/mL)</th>
                        <th>Original Concentration (µg/mL)</th>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>20</td>
                        <td>0.103</td>
                        <td>24</td>
                        <td>1200</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>10</td>
                        <td>&lt;0.1*</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                </table>
        </div>
        <div id="ex3text2">
            <p>
                Now analyze the results by using the BSA standard curve you created previously, to calculate
                 or interpolate the measured concentrations of the diluted unknown samples. Don’t forget 
                 to adjust for the dilution factor to determine the concentration of the original sample. 
                 Press the <b>Analyze</b> button.
                 <button class="btn btn-primary btn-sm" (click)="onAnalyzeClick()">Analyze</button>
            </p>
        </div>


        <div id="iMachine_1" class="cMachine">
        </div>
        <div id="iMachine_1_close" class="cMachine">
        </div>
        <div id="iTray" class="cTray" cdkDrag (cdkDragStarted)="dragStarted($event)"
             (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="!isTrayFilled2">
        </div>

        <div id="iPipette_1" class="cPipette" cdkDrag (cdkDragStarted)="dragStarted($event)"
             (cdkDragEnded)="dragEnded($event)">
            <span class="cPipetteFilled"></span>
        </div>

        <div id="iTrough"></div>
        
         <div id="iBeakerHolder">
            <div class="cBeaker">
                <span class="cBeakerFilled"></span>
                <span class="testtube-label">0</span>
            </div>
            <div class="cBeaker">
                <span class="cBeakerFilled"></span>
                <span class="testtube-label">50</span>
            </div>
            <div class="cBeaker">
                <span class="cBeakerFilled"></span>
                <span class="testtube-label">100</span>
            </div>
            <div class="cBeaker">
                <span class="cBeakerFilled"></span>
                <span class="testtube-label">150</span>
            </div>
            <div class="cBeaker">
                <span class="cBeakerFilled"></span>
                <span class="testtube-label">200</span>
            </div>
            <div class="cBeaker">
                <span class="cBeakerFilled"></span>
                <span class="testtube-label">250</span>
            </div>
        </div>
        <div id="iPipette_multichannel" class="cPipette" cdkDrag
             (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)">
            <span class="cPipetteFilled"></span>
            <span class="cPipetteFilled"></span>
            <span class="cPipetteFilled"></span>
            <span class="cPipetteFilled"></span>
            <span class="cPipetteFilled"></span>
            <span class="cPipetteFilled"></span>
        </div>
    
        <div id="iMachine_open" class="cMachine"></div>
        <div id="iMachine_close" class="cMachine">
        </div>
        <div id="iBlackCone" cdkDrag (cdkDragStarted)="dragStarted($event)"
             (cdkDragEnded)="dragEnded($event)"></div>
        <div id="iCuvette" cdkDrag (cdkDragStarted)="dragStarted($event)"
             (cdkDragEnded)="dragEnded($event)"></div>
        <div id="iCuvetteOutput"></div>

        <div id="input"></div>
        <!-- 0, 50, 100, 150, 200, 250 µg/mL -->

        <div class="alert alert-info alert-dismissible fade show" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                <strong>Experiment III: Protein Assays</strong>
        </div>

        <div class="cTimer">{{counter.min}} : {{counter.sec}}</div>
        
    </div>