/* tslint:disable:max-line-length */
import { Component } from '@angular/core';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
    selector: 'ms-lab4',
    templateUrl: './lab4.component.html',
    styleUrls: ['./lab4.component.scss']
})
export class Lab4Component {
    nTolerance = 20;
    isTray1Displayed = false;
    isTray1Filled = false;

    ph5ButtonBool = false;
    ph6ButtonBool = false;
    ph7ButtonBool = false;

    startBool = true;
    stopBool = true;

    cuvettePlaced = false;
    beakerPlaced = false;

    disableMode = false;

    repeatBool = true;

    currentPh = 5;

    ph5Done = false;
    ph6Done = false;
    ph7Done = false;

    hasTakenEznyme = false;
    hasTakenChemical = false;

    experimentNum = 1;

    constructor() {
    }

    // Action on drag started
    dragStarted(event: CdkDragStart) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    }

    // Action after drag ended.
    dragEnded(event: CdkDragEnd) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
        const strItemId: string = dragItem.id;
        this.updateOnItemDrag(strItemId);
    }

    updateOnItemDrag(strItemId: string) {
        if (strItemId === 'cuvette' || strItemId === 'cuvette-2' || strItemId === 'cuvette-3') {
            this.updateCuvette(strItemId);
        }
        if (strItemId === 'beaker' || strItemId === 'beaker-2' || strItemId === 'beaker-3') {
            this.updateBeaker(strItemId);
        }
        if (strItemId === 'micropipettor' || strItemId === 'micropipettor-2' || strItemId === 'micropipettor-3') {
            this.updateMicropipettor(strItemId);
        }
        if (strItemId === 'pipette' || strItemId === 'pipette-2' || strItemId === 'pipette-3') {
            this.updatePipette(strItemId);
        }
    }

    actionPh(ph: any) {
        this.repeatBool = true;
        this.currentPh = ph;
        if (ph === 5) {
            this.ph5Done = true;
            this.ph6ButtonBool = true;
            this.ph7ButtonBool = true;
        }
        if (ph === 6) {
            this.ph6Done = true;
            this.ph5ButtonBool = true;
            this.ph7ButtonBool = true;
        }
        if (ph === 7) {
            this.ph7Done = true;
            this.ph5ButtonBool = true;
            this.ph6ButtonBool = true;
        }
        this.disableMode = true;
    }

    updateCuvette(strItemId: string) {
        (document.getElementById('cuvette-label') as HTMLElement).style.display = 'none';
        const cuvette = (document.getElementById(strItemId) as HTMLElement);
        const hidden = (document.getElementById('cuvette-hidden') as HTMLElement);
        const cuvetteRect = document.getElementById(strItemId).getBoundingClientRect();
        const hiddenRect = document.getElementById('cuvette-hidden').getBoundingClientRect();

        if (Math.abs(cuvetteRect.left - hiddenRect.left) < 30 && Math.abs(cuvetteRect.top - hiddenRect.top) < 30) {
            cuvette.style.display = 'none';
            hidden.style.backgroundColor = 'transparent';
            hidden.style.opacity = '1';

            (document.getElementById('waterbath-label') as HTMLElement).style.display = 'none';

            this.cuvettePlaced = true;
            this.showMicropipettor();
        }

    }

    updateBeaker(strItemId: string) {
        (document.getElementById('beaker-label') as HTMLElement).style.display = 'none';
        const beaker = (document.getElementById(strItemId) as HTMLElement);
        const hidden = (document.getElementById('beaker-hidden') as HTMLElement);
        const beakerRect = document.getElementById(strItemId).getBoundingClientRect();
        const hiddenRect = document.getElementById('beaker-hidden').getBoundingClientRect();

        if (Math.abs(beakerRect.left - hiddenRect.left) < 30 && Math.abs(beakerRect.top - hiddenRect.top) < 30) {
            beaker.style.display = 'none';
            hidden.style.backgroundColor = 'transparent';
            hidden.style.opacity = '1';

            this.beakerPlaced = true;
            this.showMicropipettor();
        }
    }

    showMicropipettor() {
        if (this.cuvettePlaced && this.beakerPlaced) {
            (document.getElementById('micropipettor-label') as HTMLElement).style.display = 'block';
            if (this.experimentNum === 1) {
                (document.getElementById('micropipettor') as HTMLElement).style.display = 'block';
            } else if (this.experimentNum === 2) {
                (document.getElementById('micropipettor-2') as HTMLElement).style.display = 'block';
            } else if (this.experimentNum === 3) {
                (document.getElementById('micropipettor-3') as HTMLElement).style.display = 'block';
            }
        }
    }

    updateMicropipettor(strItemId: string) {
        const pipette = (document.getElementById(strItemId) as HTMLElement);
        const pipetteRect = document.getElementById(strItemId).getBoundingClientRect();
        const beakerRect = document.getElementById('beaker-hidden').getBoundingClientRect();
        const cuvetteRect = document.getElementById('cuvette-hidden').getBoundingClientRect();

        (document.getElementById('micropipettor-label')).style.display = 'none';

        if (!this.hasTakenEznyme && Math.abs(pipetteRect.left - beakerRect.left) < 30 && Math.abs(pipetteRect.top - beakerRect.top) < 160 && Math.abs(pipetteRect.top - beakerRect.top) > 60) {
            (document.querySelector(`#${strItemId} .micropipettorFilled`) as HTMLElement).style.opacity = '0.5';
            this.hasTakenEznyme = true;
        }
        
        if (this.hasTakenEznyme && Math.abs(pipetteRect.left - cuvetteRect.left) < 12 && Math.abs(pipetteRect.top - cuvetteRect.top) < 190 && Math.abs(pipetteRect.top - cuvetteRect.top) > 140) {
            (document.querySelector(`#${strItemId} .micropipettorFilled`) as HTMLElement).style.opacity = '0';
            (document.querySelector('#cuvette-hidden .cuvetteFilled') as HTMLElement).style.opacity = '0.5';
            pipette.style.display = 'none';
            if (this.experimentNum === 1) {
                (document.getElementById('pipette') as HTMLElement).style.display = 'block';
            } else if (this.experimentNum === 2) {
                (document.getElementById('pipette-2') as HTMLElement).style.display = 'block';
            } else if (this.experimentNum === 3) {
            (document.getElementById('pipette-3') as HTMLElement).style.display = 'block';
            }
            (document.getElementById('pipette-label') as HTMLElement).style.display = 'block';
            this.hasTakenEznyme = false;
        }
    }

    updatePipette(strItemId: string) {
        const pipette = (document.getElementById(strItemId) as HTMLElement);
        const pipetteRect = document.getElementById(strItemId).getBoundingClientRect();
        const bottleRect = document.getElementById('bottle').getBoundingClientRect();
        const cuvetteRect = document.getElementById('cuvette-hidden').getBoundingClientRect();

        (document.getElementById('pipette-label') as HTMLElement).style.display = 'none';

        if (!this.hasTakenChemical && Math.abs(pipetteRect.left - bottleRect.left) > 4 && Math.abs(pipetteRect.left - bottleRect.left) < 40 && Math.abs(pipetteRect.top - bottleRect.top) < 220 && Math.abs(pipetteRect.top - bottleRect.top) > 160) {
            (document.querySelector(`#${strItemId} .pipetteFilled`) as HTMLElement).style.opacity = '0.4';
            this.hasTakenChemical = true;
        }
        if (this.hasTakenChemical && Math.abs(pipetteRect.left - cuvetteRect.left) < 10 && Math.abs(pipetteRect.top - cuvetteRect.top) < 240 && Math.abs(pipetteRect.top - cuvetteRect.top) > 190) {
            (document.querySelector(`#${strItemId} .pipetteFilled`) as HTMLElement).style.opacity = '0';
            (document.querySelector('#cuvette-hidden .cuvetteFilled') as HTMLElement).style.backgroundColor = 'yellow';
            this.hasTakenChemical = false;
            pipette.style.display = 'none';

            this.repeatBool = false;

            this.showGraph();

            if (this.experimentNum === 3) {
                (document.getElementById('repeat') as HTMLElement).style.display = 'none';
                (document.getElementById('finish-btn') as HTMLElement).style.display = 'block';
            }
        }
    }

    hideAllGraphs() {
        (document.getElementById('ph5') as HTMLElement).style.display = 'none';
        (document.getElementById('ph6') as HTMLElement).style.display = 'none';
        (document.getElementById('ph7') as HTMLElement).style.display = 'none';
        (document.getElementById('ph56') as HTMLElement).style.display = 'none';
        (document.getElementById('ph57') as HTMLElement).style.display = 'none';
        (document.getElementById('ph67') as HTMLElement).style.display = 'none';
        (document.getElementById('ph567') as HTMLElement).style.display = 'none';
    }

    showGraph() {
        const ph5 = (document.getElementById('ph5') as HTMLElement);
        const ph6 = (document.getElementById('ph6') as HTMLElement);
        const ph7 = (document.getElementById('ph7') as HTMLElement);
        const ph56 = (document.getElementById('ph56') as HTMLElement);
        const ph57 = (document.getElementById('ph57') as HTMLElement);
        const ph67 = (document.getElementById('ph57') as HTMLElement);
        const ph567 = (document.getElementById('ph567') as HTMLElement);

        this.hideAllGraphs();

        (document.getElementById('repeat') as HTMLElement).style.display = 'block';

        
        if (this.ph5Done && !this.ph6Done && !this.ph7Done) {
            ph5.style.display = 'block';
        }
        if (!this.ph5Done && this.ph6Done && !this.ph7Done) {
            ph6.style.display = 'block';
        }
        if (!this.ph5Done && !this.ph6Done && this.ph7Done) {
            ph7.style.display = 'block';
        }

        if (this.ph5Done && this.ph6Done && !this.ph7Done) {
            ph56.style.display = 'block';
        } else if (this.ph5Done && !this.ph6Done && this.ph7Done) {
            ph57.style.display = 'block';
        } else if (!this.ph5Done && this.ph6Done && this.ph7Done) {
            ph67.style.display = 'block';
        } else if (this.ph5Done && this.ph6Done && this.ph7Done) {
            ph567.style.display = 'block';
        }

    }

    actionRepeat() {
        this.experimentNum++;
        this.disableMode = false;
        this.repeatBool = true;
    
        this.cuvettePlaced = false;
        this.beakerPlaced = false;

        const cuvette = (document.getElementById('cuvette') as HTMLElement);
        const hiddenCuvette = (document.getElementById('cuvette-hidden') as HTMLElement);

        const beaker = (document.getElementById('beaker') as HTMLElement);
        const hiddenBeaker = (document.getElementById('beaker-hidden') as HTMLElement);

        hiddenCuvette.style.backgroundColor = 'green';
        hiddenCuvette.style.opacity = '0.3';
        (document.querySelector('#cuvette-hidden .cuvetteFilled') as HTMLElement).style.backgroundColor = '#2063aa';
        (document.querySelector('#cuvette-hidden .cuvetteFilled') as HTMLElement).style.opacity = '0.1';

        hiddenBeaker.style.backgroundColor = 'green';
        hiddenBeaker.style.opacity = '0.3';

        (document.getElementById('cuvette-label') as HTMLElement).style.display = 'block';
        (document.getElementById('beaker-label') as HTMLElement).style.display = 'block';
        (document.getElementById('waterbath-label') as HTMLElement).style.display = 'block';

        if (this.experimentNum === 2) {
            (document.getElementById('cuvette-2') as HTMLElement).style.display = 'block';
            (document.getElementById('beaker-2') as HTMLElement).style.display = 'block';
        } else if (this.experimentNum === 3) {
            (document.getElementById('cuvette-3') as HTMLElement).style.display = 'block';
            (document.getElementById('beaker-3') as HTMLElement).style.display = 'block';
        } 

        this.ph5ButtonBool = false;
        this.ph6ButtonBool = false;
        this.ph7ButtonBool = false;

        if (this.ph5Done) {
            this.ph5ButtonBool = true;
        }
        if (this.ph6Done) {
            this.ph6ButtonBool = true;
        }
        if (this.ph7Done) {
            this.ph7ButtonBool = true;
        }
    }

    actionExplore() {
        (document.getElementById('explore-text') as HTMLElement).style.display = 'block';
        (document.getElementById('finish-btn') as HTMLElement).style.display = 'none';
    }
}
