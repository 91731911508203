import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
    selector: 'ms-lab1',
    templateUrl: './lab1.component.html',
    styleUrls: ['./lab1.component.scss']
})
export class Lab1Component {
    placementTolerance = 30;

    buretteIsPlaced = false;
    beakerIsPlaced = false;

    buretteButtonBool = true;

    counter = 0;
    concentrationVal: number;
    concentrations: string[] = ['0.05 M', '0.1 M', '0.2 M'];
    conc1ButtonBool = true;
    conc2ButtonBool = true;
    conc3ButtonBool = true;

    dropButtonBool = true;

    phValue = 0;

    ph05: number[] = [5.24, 5.58, 5.8, 5.97, 6.12, 6.27, 6.4, 6.55, 6.71, 6.9, 7.17, 7.68, 8.5];
    // tslint:disable-next-line: max-line-length
    ph1: number[] = [4.92, 5.24, 5.43, 5.58, 5.7, 5.8, 5.89, 5.97, 6.05, 6.12, 6.2, 6.27, 6.3, 6.4, 6.48, 6.55, 6.63, 6.71, 6.8, 6.9, 7.02, 7.17, 7.36, 7.68, 8.5];
    // tslint:disable-next-line: max-line-length
    ph2: number[] = [4.61, 4.92, 5.11, 5.24, 5.35, 5.43, 5.51, 5.58, 5.64, 5.7, 5.75, 5.8, 5.82, 5.89, 5.93, 5.97, 6.01, 6.05, 6.09, 6.12, 6.16, 6.20, 6.23, 6.27, 6.3, 6.33, 6.37, 6.4, 6.44, 6.48, 6.51, 6.55, 6.59, 6.63, 6.67, 6.71, 6.75, 6.8, 6.85, 6.9, 6.96, 7.02, 7.09, 7.17, 7.25, 7.36, 7.49, 7.68, 7.99, 8.5];

    constructor(public fb: FormBuilder) { }

    // Action on drag started
    dragStarted(event: CdkDragStart) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    }

    // Action after drag ended.
    dragEnded(event: CdkDragEnd) {
        const dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
        const strItemId: string = dragItem.id;
        console.log(strItemId);
        this.updateOnItemDrag(strItemId);
    }

    updateOnItemDrag(strItemId: string) {
        if (strItemId === 'burette') {
            this.updateBurette();
        } else if (strItemId === 'beaker') {
            this.updateBeaker();
        }
    }

    updateBurette() {
        (document.getElementById('biuret-label') as HTMLElement).style.display = 'none';
        const burette = (document.getElementById('burette') as HTMLElement);
        const buretteRect = document.getElementById('burette').getBoundingClientRect();
        const buretteHiddenRect = document.getElementById('burette-hidden').getBoundingClientRect();
        // If burette is dragged close enough to in-position burette
        // tslint:disable-next-line: max-line-length
        if (Math.abs(buretteRect.left - buretteHiddenRect.left) < this.placementTolerance && Math.abs(buretteRect.top - buretteHiddenRect.top) < 60) {
            (document.getElementById('stopcock-label') as HTMLElement).style.display = 'block';
            // Hide draggable burette and display in-position burette
            burette.style.display = 'none';
            (document.getElementById('burette-hidden') as HTMLElement).style.opacity = '1';

            this.buretteIsPlaced = true;
            // If beaker is also placed, enable "Fill Burette" button and display magnet
            if (this.beakerIsPlaced) {
                this.buretteButtonBool = false;
                (document.querySelector('.magnet') as HTMLElement).style.display = 'block';
            }
        }
    }

    updateBeaker() {
        (document.getElementById('beaker-label') as HTMLElement).style.display = 'none';
        (document.getElementById('stirplate-label') as HTMLElement).style.display = 'none';
        const beaker = (document.getElementById('beaker') as HTMLElement);
        const beakerRect = document.getElementById('beaker').getBoundingClientRect();
        const beakerHiddenRect = document.getElementById('beaker-hidden').getBoundingClientRect();
        // If beaker is dragged close enough to in-position beaker
        // tslint:disable-next-line: max-line-length
        if (Math.abs(beakerRect.left - beakerHiddenRect.left) < this.placementTolerance && Math.abs(beakerRect.top - beakerHiddenRect.top) < this.placementTolerance) {
            // Hide draggable beaker and display in-position beaker
            beaker.style.display = 'none';
            (document.getElementById('beaker-hidden') as HTMLElement).style.opacity = '0.8';

            this.beakerIsPlaced = true;
            // If burette is also placed, enable "Fill Burette" button and display magnet
            if (this.buretteIsPlaced) {
                 this.buretteButtonBool = false;
                 (document.querySelector('.magnet') as HTMLElement).style.display = 'block';
            }
        }
    }

    // Display filled burette and enable concentration buttons
    onBuretteBtnClicked() {
        (document.getElementById('burette-filled') as HTMLElement).style.opacity = '1';
        this.conc1ButtonBool = false;
        this.conc2ButtonBool = false;
        this.conc3ButtonBool = false;
    }

    onConcentrationBtnClicked(value: any) {
        this.concentrationVal = value;
        if (value === 0.05) {
            this.conc2ButtonBool = true;
            this.conc3ButtonBool = true;
        } else if (value === 0.1) {
            this.conc1ButtonBool = true;
            this.conc3ButtonBool = true;
        } else {
            this.conc1ButtonBool = true;
            this.conc2ButtonBool = true;
        }
        (document.querySelector('.beaker-filled') as HTMLElement).style.opacity = '0.6';
        (document.getElementById('push-btn') as HTMLElement).style.display = 'block';
    }

    // Enable "Drop 1 mL of NaOH" button
    onStopcockClicked() {
        (document.getElementById('stopcock-label') as HTMLElement).style.display = 'none';
        this.dropButtonBool = false;
    }

    onDropBtnClicked() {
        const waterDrop = document.getElementById('water-drop');
        const newWaterDrop = waterDrop.cloneNode(true) as HTMLElement;
        newWaterDrop.style.display = 'block';
        waterDrop.parentNode.appendChild(newWaterDrop);
        // tslint:disable-next-line: no-shadowed-variable
        setTimeout((newWaterDrop) => newWaterDrop.style.display = 'none', 1500, newWaterDrop);
        this.updatePh(this.concentrationVal);
    }

    updatePh(concentration: number) {
        if (concentration === 0.05) {
            this.phValue = this.ph05[this.counter];
            this.counter++;
            if (this.counter === 13) {
                this.dropButtonBool = true;
                this.showResults(0.05);
            }
        } else if (concentration === 0.1) {
            this.phValue = this.ph1[this.counter];
            this.counter++;
            if (this.counter === 25) {
                this.dropButtonBool = true;
                this.showResults(0.1);
            }
        } else {
            this.phValue = this.ph2[this.counter];
            this.counter++;
            if (this.counter === 50) {
                this.dropButtonBool = true;
                this.showResults(0.2);
            }
        }
    }

    // Hide instructions and display graph, exploration questions
    showResults(concentration: number) {
        (document.querySelector('.text') as HTMLElement).style.opacity = '0';
        if (concentration === 0.05) {
            (document.getElementById('graph-one') as HTMLElement).style.display = 'block';
        } else if (concentration === 0.1) {
            (document.getElementById('graph-two') as HTMLElement).style.display = 'block';
        } else {
            (document.getElementById('graph-three') as HTMLElement).style.display = 'block';
        }
        (document.getElementById('exploration-text') as HTMLElement).style.display = 'block';
    }

}
